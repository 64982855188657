import React, { useEffect } from "react"
import { auth, db } from "../firebase"
import { doc, onSnapshot } from "firebase/firestore"
import { formatCash, formatNumber } from "../utils"

import { Button } from "@mui/material"
import LoginPage from "./Login"
import { PopupService } from "react-ui-components/dist/components"
import { useAuthState } from "react-firebase-hooks/auth"

export const UserPoints = function UserPoints(props: {
  style: any
  size: any
  iconColor: any
  iconStyle: any
  showMinDigits: any
  precision: any
  complete: any
  variant?: "contained" | undefined
  className: any
}) {
  const {
    style,
    size,
    iconColor,
    iconStyle,
    showMinDigits,
    precision,
    complete,
    variant = "contained",
    className,
  } = props

  const $styles = { ...$container, ...style }
  const $iconStyles = { ...$icon, ...iconStyle }
  const info: any = {}
  const { points, isLoggedIn, setPoints } = info
  const [user] = useAuthState(auth)

  useEffect(() => {
    if (!isLoggedIn) {
      return
    }

    if (!user) {
      PopupService.pushPopup("User not logged in")
      return
    }

    const id = user.uid

    const querySnapshot = doc(db, "users", id)
    return onSnapshot(querySnapshot, {
      next: (snap) => {
        setPoints(snap?.data()?.points || 0)
      },
      error: (data) => {
        console.log("USERS-->", data)
      },
    })
  }, [user])

  return (
    <Button
      variant={variant}
      style={$styles}
      className={`flex flex-row gap-1 p-1 ${className}`}
      startIcon={
        <img
          src={require("../logo.svg")}
          width={size || 20}
          style={$iconStyles}
          color={iconColor}
          alt="winfacil coin"
        />
      }
      // style={{ backgroundColor: colors.transparent }}
      onClick={() => {
        PopupService.setPopup(<LoginPage />)
      }}
    >
      <span className="truncate">
        {complete ? formatNumber(points) : formatCash(Math.floor(points), showMinDigits, precision)}
      </span>
    </Button>
  )
}

const $container = {
  justifyContent: "center",
  flexDirection: "row",
  marginRight: 10,
}

const $icon = {
  marginLeft: 1,
}

import { TableCell, TableRow } from "@mui/material"
import { doc, updateDoc, deleteField } from "firebase/firestore"
import { db } from "../../firebase"
import { editColumn } from "../core/CreateEditOptions/editColumn"
import update from "../core/CreateEditOptions/update"
import { contentSchema } from "./contentSchema"
import { imageSchema } from "./imageSchema"
import { disabledColumnItem } from "../core/disabledColumnItem"
import { SingleObject } from "../core/SingleObject"
import React from "react"

export const options = ({ path, data, onSuccess, onEditSuccess }: any) => {
  return {
    filterType: "dropdown",
    responsive: "standard",
    selectableRows: "single",
    expandableRows: true,
    renderExpandableRow: (rowData: string | any[], rowMeta: { dataIndex: string | number }) => {
      console.log(rowData, rowMeta)

      const obj = data[rowMeta.dataIndex]
      const content: any = obj.content || {}
      const image = obj.image || {}
      const id: any = obj.id
      return (
        <>
          <TableRow>
            <TableCell colSpan={rowData.length}>
              <SingleObject
                columns={[
                  editColumn({
                    data: content,
                    // imagePropName,
                    path: path + `/${id}`,
                    schema: contentSchema,
                    // setLoading,
                    onEditSuccess: (response: { result: { formData: any }; close: () => void }) => {
                      update({
                        path: `${path}/${id}`,
                        key: "content",
                        data: response.result.formData,
                      } as any)
                      response.close()
                      onEditSuccess && onEditSuccess({ ...response, id })
                    },
                    column2object: (columns: any[]) => ({
                      disabled: !!columns[2],
                      order: columns[3] || "",
                      text: columns[4] || "",
                      translationKey: columns[5] || "",
                    }),
                  } as any),
                  disabledColumnItem(),
                  "order",
                  "text",
                  "translationKey",
                ]}
                title={"Content"}
                schema={contentSchema}
                data={content}
                // id={id}
                path={path}
                onEditSuccess={undefined}
                imagePropName={undefined}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={rowData.length}>
              <SingleObject
                imagePropName="url"
                columns={[
                  editColumn({
                    imagePropName: "url",
                    data: image,
                    // imagePropName,
                    path: path + `/${id}`,
                    schema: imageSchema,
                    // setLoading,
                    onEditSuccess: (response: { result: { formData: any }; close: () => void }) => {
                      update({
                        path: `${path}/${id}`,
                        key: "image",
                        data: response.result.formData,
                      } as any)
                      response.close()
                      onEditSuccess && onEditSuccess({ ...response, id })
                    },
                    column2object: (columns: any[]) => ({
                      disabled: !!columns[2],
                      order: columns[3] || "",
                      url: columns[4] || "",
                      resizeMode: columns[5] || "",
                    }),
                  } as any),
                  disabledColumnItem(),
                  "order",
                  "url",
                  "resizeMode",
                ]}
                title={"Image"}
                schema={imageSchema}
                data={image}
                // id={id}
                path={path}
                onEditSuccess={undefined}
              />
            </TableCell>
          </TableRow>
        </>
      )
    },
    onRowsDelete: async (rowsDeleted: { data: any[] }, rowData: any, newTableData: any) => {
      console.log({ rowsDeleted, rowData, newTableData })

      const cityRef = doc(db, typeof path === "string" ? path : path.join("/"))

      // Get a new write batch
      // const batch = writeBatch(db);

      rowsDeleted.data.forEach(async (element: { dataIndex: string | number }) => {
        const row = data[element.dataIndex]
        const key = row ? row.key : ""
        const laRef = doc(db, path.join("/"))
        await updateDoc(cityRef, {
          [key]: deleteField(),
        })
      })

      onSuccess && onSuccess(data)

      // await updateDoc(cityRef, {
      //   [key]: deleteField(),
      // });

      // Commit the batch
      // await batch.commit();

      return false
    },
  }
}

import React from "react"
import { useTranslation } from "react-i18next"

// export interface ICountry {
//   currency: string
//   disabled: boolean
//   display: string
//   icon: string
//   key: string
// }
// export interface ILocation {
//   [key: string]: {
//     [key: string]: string[]
//   }
// }
export const DEFAULT_COUNTRY = {
  currency: "USD",
  disabled: false,
  display: "United States",
  icon: "https://firebasestorage.googleapis.com/v0/b/winfacil-250ad.appspot.com/o/assets%2Fus-flag.jpeg?alt=media&token=b86edf51-856a-458f-b491-8f05bd979e7e",
  key: "US",
}

export const DEFAULT_AVAILABLE_LOCATION = {
  US: {
    Florida: ["Orlando"],
    "New York": ["Queens"],
  },
}

const val = {
  location: DEFAULT_COUNTRY.key,
  availableLocations: DEFAULT_AVAILABLE_LOCATION,
}
export const LocationCustomWidget = ({ options, value, onChange, label, ...rest }: any) => {
  // console.log('SelectWidget--rest->',label, rest)
  const { t } = useTranslation()

  return <>TEST: {JSON.stringify(value)}</>
}

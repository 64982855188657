import {
  Autocomplete,
  Avatar,
  CircularProgress,
  FormLabel,
  TextField,
  Tooltip,
} from "@mui/material"
import { DataProvider, ListComponent } from "../GenericListComponent"
import { ImageWidget, LanguageDropdownWidget, SelectWidget } from "./CustomWidgets"
import { collection, getDocs, limit, query, startAfter, where } from "firebase/firestore"
import { useEffect, useState } from "react"

import { LocationCustomWidget } from "./LocationCustomWidget"
import React from "react"
import { db } from "../firebase"

const AutocompleteWidget = (props: { value?: "" | undefined; onChange: any; id: any }) => {
  const { value = "", onChange, id } = props // Ensure value is always a string
  console.log("AutocompleteWidget value:", value)

  const [users, setUsers] = useState<any>([])
  const [userSearch, setUserSearch] = useState("")
  const [loadingUsers, setLoadingUsers] = useState(false)
  const [lastVisibleUser, setLastVisibleUser] = useState<any>(null)

  const fetchUsers = async (searchTerm = "", startAfterUser = null) => {
    setLoadingUsers(true)
    const usersRef = collection(db, "users")

    let userQuery = query(
      usersRef,
      where("name", ">=", searchTerm || ""), // Only fetch users where 'name' field exists and matches the search
      where("name", "<=", (searchTerm || "") + "\uf8ff"),
      limit(100),
    )

    if (startAfterUser) {
      userQuery = query(userQuery, startAfter(startAfterUser))
    }

    const querySnapshot = await getDocs(userQuery)
    const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1]
    setLastVisibleUser(lastVisible)

    const userList = querySnapshot.docs
      .map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }))
      .filter((user: any) => user.name) // Ensure the user has a name

    setUsers(userList)
    setLoadingUsers(false)
  }

  useEffect(() => {
    fetchUsers()
  }, [])

  const handleUserSearchChange = (event: any, value: any) => {
    setUserSearch(value)
    fetchUsers(value)
  }

  return (
    <Autocomplete
      options={users}
      value={users.find((user: any) => user.id === value) || null} // Find the selected user
      getOptionLabel={(option) => option.name || option.id || "Unknown User"}
      onChange={(event, newValue) => {
        onChange(newValue ? newValue.id : "") // Set the selected user's ID
      }}
      inputValue={userSearch}
      onInputChange={handleUserSearchChange}
      loading={loadingUsers}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {option.name || option.id || "Unknown User"}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select User"
          variant="outlined"
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loadingUsers ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}
const data = {
  getListLocation(id: any) {
    return ["sponsors"]
  },
  EDIT_TITLE: "Edit Sponsors",
  ADD_TITLE: "Add Sponsor",
  LOADING_MESSAGE: "Loading sponsors...",
  TITLE: "Sponsors",
  IMAGES_BUCKET: "sponsorsImages",

  onRowClick:
    (list: { [x: string]: any }, navigate: (arg0: string, arg1: { state: any }) => void) =>
    (
      arr: any,
      index: { dataIndex: string | number } /*: {rowIndex: number, dataIndex: number}*/,
    ) => {
      console.log("on row click----->", index.dataIndex)
      const state = list[index.dataIndex]
      const id = state.id
      navigate(`/sponsors/${id}`, { state })
    },

  columnsProperties: [
    { hidden: true, name: "id", label: "Id" },
    { required: false, name: "disabled", label: "Disabled", type: "boolean" },
    { required: false, name: "expirationDate", label: "Expiration Date" },

    { required: true, name: "name", label: "Name" },
    { required: true, type: "integer", name: "order", label: "Order" },

    {
      type: "string",
      name: "iconUrl",
      label: "Icon URL",

      options: {
        customBodyRender: (value: string | undefined) => {
          return (
            <div className="flex flex-col flex-wrap overflow-auto w-40">
              {!value ? null : <img src={value} />}
            </div>
          )
        },
      },
    },
    {
      type: "string",
      name: "image",
      label: "Image URL",
      options: {
        customBodyRender: (value: string | undefined) => {
          return (
            <div className="flex flex-col flex-wrap overflow-auto w-40">
              {!value ? null : <img src={value} />}
            </div>
          )
        },
      },
    },

    { required: false, name: "description", label: "Description" },
    { required: true, name: "language", label: "Language" },
    {
      required: false,
      name: "location",
      type: "object",
      properties: {
        country: { type: "string", title: "Country" },
        state: { type: "string", title: "State" },
        city: { type: "string", title: "City" },
        street: { type: "string", title: "Street" },
        county: { type: "string", title: "County" },
        zipCode: { type: "string", title: "Zip Code" },
      },
      options: {
        customBodyRender: (value: {
          [x: string]:
            | string
            | number
            | boolean
            | React.ReactElement<any, string | React.JSXElementConstructor<any>>
            | Iterable<React.ReactNode>
            | React.ReactPortal
            | Iterable<React.ReactNode>
            | null
            | undefined
        }) => {
          return (
            <div className="flex flex-col flex-wrap overflow-auto w-40">
              {!value
                ? null
                : Object.keys(value).map((k) => {
                    return (
                      <label>
                        <b>{k}:</b>
                        {value[k]}
                      </label>
                    )
                  })}
            </div>
          )
        },
      },
    },
    {
      required: false,
      name: "userIds",
      type: "array",
      items: {
        type: "string",
      },
      options: {
        customBodyRender: (value: any[]) => {
          return (
            <div className="flex flex-col flex-wrap overflow-auto w-40">
              {!value
                ? null
                : value.map(
                    (
                      item:
                        | string
                        | number
                        | boolean
                        | React.ReactElement<any, string | React.JSXElementConstructor<any>>
                        | Iterable<React.ReactNode>
                        | React.ReactPortal
                        | Iterable<React.ReactNode>
                        | null
                        | undefined,
                      i: number,
                    ) => {
                      return (
                        <label key={i}>
                          <b>
                            {i + 1}-{item}
                          </b>
                        </label>
                      )
                    },
                  )}
            </div>
          )
        },
      },
    },
  ],

  uiSchema: {
    iconUrl: {
      "ui:widget": "image",
    },
    image: {
      "ui:widget": "image",
    },
    language: {
      "ui:widget": LanguageDropdownWidget,
    },
    location: {
      "ui:widget": LocationCustomWidget,
    },
    userIds: {
      items: {
        "ui:widget": AutocompleteWidget,
      },
    },
    expirationDate: {
      "ui:widget": "datetime",
    },

    description: {
      "ui:widget": "textarea",
    },
  },

  async imageProcessor(
    fromData: { iconUrl: any; image: any },
    handleUpload: (arg0: any, arg1: string, arg2: any) => any,
    id: any,
  ) {
    if (fromData.iconUrl) {
      fromData.iconUrl = await handleUpload(id, this.IMAGES_BUCKET, fromData.iconUrl)
    }

    if (fromData.image) {
      fromData.image = await handleUpload(id, this.IMAGES_BUCKET, fromData.image)
    }

    return fromData
  },
  widgets: { SelectWidget, image: ImageWidget },
}

const Sponsors = () => {
  return (
    <div>
      <DataProvider {...data}>
        <ListComponent />
      </DataProvider>
    </div>
  )
}
export default Sponsors

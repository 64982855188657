import { Alert, Card, CardContent, CardHeader } from "@mui/material"
import {
  CountryLocationSelector,
  IRaffle,
  LoadingService,
  PopupService,
} from "react-ui-components/dist/components"
import React, { useEffect, useState } from "react"
import Toolbar, { EditButton } from "../core/Toolbar"
import { raffleSchema, raffleUISchema } from "react-ui-components/dist/components/raffle"

import CustomObjectFieldTabs from "./CustomObjectFieldTabs"
import { DateTimeWidget } from "react-ui-components/dist/components/widgets"
import { DisableMessage } from "../../utils/DisableMessage"
import { Form } from "@rjsf/mui"
import { GlobalRafflesConfig } from "./GlobalRaffleConfig"
import { LocationProvider } from "../../LocationSelector"
import Raffles from "react-ui-components/dist/components/Raffles"
import SplitPane from "react-split-pane"
import { auth } from "../../firebase"
import { deleteCollectionAndImages } from "../../GenericListComponent"
import { handleUpload } from "../../utils"
import { useAppConfiguration } from "../AppConfigurationService"
import { useAuthState } from "react-firebase-hooks/auth"
import { useNavigate } from "react-router-dom"
import validator from "@rjsf/validator-ajv8"

function CountryUIWidget({ onChange, ...rest }: any) {
  function onHandleChange(_name: string, value: any) {
    onChange(value)
  }
  return (
    <LocationProvider {...rest}>
      <CountryLocationSelector {...rest} onChange={onHandleChange} />
    </LocationProvider>
  )
}

export function RaffleEditView({
  raffle: raffleData,
  rafflesConfig,
}: {
  raffle: IRaffle
  rafflesConfig?: any
}) {
  raffleData = raffleData || {}
  const [isEditing, setIsEditing] = useState(false)
  const handleEditClick = () => setIsEditing(!isEditing)
  const navigate = useNavigate()
  const [user] = useAuthState(auth)

  const [raffle, setRaffle] = useState(raffleData)
  const { configuration } = useAppConfiguration()
  const [config, setConfig] = useState(configuration.rafflesScreen)
  const { saveRaffle, disableRaffle, duplicateRaffle } = useAppConfiguration()

  useEffect(() => {
    setConfig(rafflesConfig || configuration.rafflesScreen)
  }, [rafflesConfig])

  useEffect(() => {
    setRaffle(raffleData)
  }, [raffleData])

  const handleFormChange = ({ formData }) => {
    setRaffle(formData)
  }

  const onSubmit = async ({ formData }) => {
    try {
      LoadingService.show()
      if (formData.raffleImages?.length > 0) {
        const imagesURLs = await Promise.all(
          formData.raffleImages.map((image: string, index: number) => {
            return handleUpload({
              image,
              id: raffle.id,
              fieldName: "assets",
              imagesBucket: "raffles",
              prevValue: raffleData.raffleImages?.[index],
            })
          }),
        )
        formData.raffleImages = imagesURLs
      }

      const data = await saveRaffle(formData)

      setRaffle(data)
    } catch (error: any) {
      console.log("Error uploading raffles images: ", formData.raffleImages)
      PopupService.pushPopup(error.message)
    } finally {
      LoadingService.hide()
    }
  }

  const onDuplicate = async () => {
    try {
      LoadingService.show()

      const result = await duplicateRaffle(raffleData)
      navigate(`/raffles/${result.id}`)
    } catch (error: any) {
      console.log("Error duplicating raffle: ", raffleData.raffleImages)
      PopupService.pushPopup(error.message)
    } finally {
      LoadingService.hide()
    }
  }

  return (
    <Card>
      <CardHeader
        avatar={
          <Toolbar
            disabled={raffle.disabled}
            onDelete={async function (): void {
              PopupService.setConfirm(
                "Are you sure you want to delete this raffle?",
                async (answer) => {
                  if (/ok/i.test(answer)) {
                    try {
                      LoadingService.setLoading(true)

                      await deleteCollectionAndImages({
                        bucketPath: "rafflesImages",
                        collectionPath: "raffles",
                        documentIds: [raffleData.id],
                      })
                      navigate("/raffles")
                      console.log("Collection and images deleted successfully.")
                    } catch (error: any) {
                      const msg = "Error deleting collection and images"
                      LoadingService.setLoading(true, msg, true)
                      console.error(msg, error)
                      PopupService.pushPopup(`${msg}\n${error.message}`)
                    } finally {
                      LoadingService.setLoading(false)
                    }
                  }
                },
              )
            }}
            onDuplicate={onDuplicate}
            onDisable={async function (): void {
              try {
                LoadingService.show()
                const disabled = !raffle.disabled
                await disableRaffle(raffle.id, disabled)
                setRaffle({ ...raffle, disabled })
              } catch (error) {
                PopupService.pushPopup(error.message)
              } finally {
                LoadingService.hide()
              }
            }}
          />
        }
        action={<EditButton onEdit={handleEditClick} />}
      />
      <CardContent className="h-screen overflow-auto">
        <GlobalRafflesConfig
          onChange={(formData) => {
            setConfig(formData)
          }}
        />

        <SplitPane
          split="vertical"
          minSize={200}
          defaultSize={isEditing ? "50%" : "100%"}
          className="overflow-auto"
        >
          {isEditing ? (
            <Card>
              <CardContent>
                <div className="h-screen overflow-auto">
                  <Form
                    formData={raffle}
                    // fields={{ LocationProvider }}
                    widgets={{ DateTimeWidget }}
                    schema={raffleSchema}
                    uiSchema={{
                      ...raffleUISchema,
                      country: {
                        "ui:widget": CountryUIWidget,
                      },
                      raffleLocation: {
                        country: {
                          "ui:widget": CountryUIWidget,
                        },
                      },
                    }}
                    validator={validator}
                    onChange={handleFormChange}
                    onSubmit={onSubmit}
                    // onError={(error) => onError && onError(error)}
                    templates={{
                      ObjectFieldTemplate: CustomObjectFieldTabs,
                    }}
                    formContext={{ className: "m-5" }}
                  />
                </div>
              </CardContent>
            </Card>
          ) : null}

          <Card>
            <CardContent>
              <div className="h-screen overflow-auto">
                <DisableMessage disabled={raffle.disabled} />
                <Raffles
                  raffles={[{ ...raffle, disabled: false }]}
                  fetchLikes={() => {}}
                  user={user}
                  handleLike={async (raffleId: string) => {}}
                  liked={true}
                  likeCount={100}
                  loading={false}
                  likeLoading={false}
                  rafflesConfig={config}
                  termsIconProps={{
                    src: require("../../images/termsIcon.png"),
                    style: { height: 30 },
                  }}
                />
              </div>
            </CardContent>
          </Card>
        </SplitPane>
      </CardContent>
    </Card>
  )
}

import React from "react"

const ReceiptContentExtractor = () => {
  return (
    <a
      rel="noreferrer"
      target="_blank"
      href="https://console.cloud.google.com/ai/document-ai/locations/us/processors/753ba22d79a60091/v2/get-started?project=winfacil-250ad"
    >
      Go to Receipt Content Extractor on Google Cloud
    </a>
  )
}

export default ReceiptContentExtractor

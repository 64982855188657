import React, { useState } from "react"

import { Grid } from "@mui/material"
import TicketForm from "../TicketForm"
import TicketsTable from "../TicketsTable"
import { useParams } from "react-router-dom"

const RaffleTickets = () => {
  const { id: raffleId } = useParams()
  const [loading, setLoading] = useState(false)

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TicketForm
            raffleId={raffleId}
            onSuccess={() => {
              setLoading(true)

              setTimeout(() => {
                setLoading(false)
              }, 1000)
            }}
          />
        </Grid>
        <Grid item xs={12}>
          {loading ? <h1>Loading...</h1> : <TicketsTable raffleId={raffleId} />}
        </Grid>
      </Grid>
    </>
  )
}

export default RaffleTickets

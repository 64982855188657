import Form from "@rjsf/material-ui"
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react"
import { fields } from "./fields"
import { uiSchema } from "./uiSchema"
import validator from "@rjsf/validator-ajv8"
import { widgets } from "./widgets"

const CreateEdit = ({ onSuccess, title, data, bucket, schema, imagePropName, path }: any) => {
  schema = typeof schema === "function" ? schema({ title }) : schema
  return (
    <Form
      formData={data}
      fields={fields({ bucket })}
      widgets={widgets({ bucket })}
      schema={schema}
      uiSchema={uiSchema({ bucket, propName: imagePropName })}
      validator={validator}
      // onChange={console.log('changed')}
      onSubmit={(result) => {
        onSuccess && onSuccess(result)
      }}
      onError={(error) => console.error("errors", error)}
    />
  )
}

export default CreateEdit

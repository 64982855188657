/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */

import "./index.css"
import "./router.css"
import "@fortawesome/react-fontawesome"

import { LoadingProvider, PopupProvider } from "react-ui-components/dist/components"

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { AppConfigurationProvider } from "./components/AppConfigurationService"
import { I18nextProvider } from "react-i18next"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { MuiProvider } from "./MuiProvider"
import React from "react"
import ReactDOM from "react-dom/client"
import { Routes } from "./routes"
import { createTheme } from "@mui/material"
import i18n from "./i18n"
import reportWebVitals from "./reportWebVitals"

const theme = createTheme({
  palette: {
    mode: "light", // or 'dark'
  },
})

const root = ReactDOM.createRoot(document.getElementById("root") as any)
root.render(
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <I18nextProvider i18n={i18n}>
      <React.StrictMode>
        <AppConfigurationProvider>
          <PopupProvider>
            <MuiProvider theme={theme}>
              <LoadingProvider>
                <Routes />
              </LoadingProvider>
            </MuiProvider>
          </PopupProvider>
        </AppConfigurationProvider>
      </React.StrictMode>
    </I18nextProvider>
  </LocalizationProvider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

import { Box, Tab, Tabs, Typography } from "@mui/material"

import { LocationProvider } from "../../LocationSelector"
import { ObjectFieldTemplateProps } from "@rjsf/utils"
import React from "react"

const replacerFunc = () => {
  const visited = new WeakSet()
  return (key, value) => {
    if (typeof value === "object" && value !== null) {
      if (visited.has(value)) {
        return
      }
      visited.add(value)
    }
    return value
  }
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
  className?: string
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  }
}

const CustomObjectFieldTabs: React.FC<ObjectFieldTemplateProps> = ({
  properties,
  title,
  description,
  required,
  formData,
}) => {
  const [value, setValue] = React.useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  // Separate properties into those that are objects and those that are not
  const nonObjectProperties = properties.filter(
    (property) =>
      property.content.props.schema.type !== "object" &&
      property.content.props.schema.type !== "array",
  )
  const objectProperties = properties.filter(
    (property) =>
      property.content.props.schema.type === "object" ||
      property.content.props.schema.type === "array",
  )

  return (
    <Box sx={{ width: "100%" }}>
      {title && (
        <Typography variant="h6" gutterBottom>
          {title} {required ? "*" : ""}
        </Typography>
      )}
      {description && (
        <Typography variant="body2" gutterBottom>
          {description}
        </Typography>
      )}

      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="Object properties tabs"
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab label="General" {...a11yProps(0)} />
        {objectProperties.map((property, index) => (
          <Tab key={property.name} label={property.name} {...a11yProps(index + 1)} />
        ))}
      </Tabs>

      <TabPanel value={value} index={0}>
        {nonObjectProperties.map((property) => (
          <div key={property.name}>{property.content}</div>
        ))}
      </TabPanel>

      {objectProperties.map((property, index) => (
        <TabPanel
          key={property.name}
          value={value}
          index={index + 1}
          className="flex flex-col gap-5"
        >
          <LocationProvider formData={formData?.[property.name]}>
            {property.content}
          </LocationProvider>
        </TabPanel>
      ))}
    </Box>
  )
}

export default CustomObjectFieldTabs

import reduce from "lodash.reduce"
const getMap = ({ item, path }: any) => {
  if (!item) {
    return []
  }

  item = reduce(
    item,
    (result: { [x: string]: any }, value: any, key: string | number) => {
      result[key] = typeof result[key] === "undefined" ? "" : result[key]
      return result
    },
    item,
  )

  const arr = [
    {
      path: path || "",
      key: "",
      ...item,
    },
  ]
  return arr
}
export default getMap

import { JSXElementConstructor, ReactElement, ReactNode } from "react"

import React from "react"
import { pascalCase } from "../utils"

export const ID = { hidden: true, name: "id", label: "Id" }
export const CREATED_DATE = {
  hidden: true,
  name: "createdDate",
  label: "Created Date",
  type: "number",
}
export const CREATED_BY = { hidden: true, name: "createdBy", label: "Created By" }
export const DISABLED = { name: "disabled", label: "Disabled", type: "boolean" }
export const NAME = { required: true, name: "name", label: "Name" }
export const getProp = (props: any, type: "string" | "number" | "array" = "string") => {
  const typeofProps = typeof props
  let name = ""
  let label = ""

  let required = true
  let otherProps: any = {}
  if ("string" === typeofProps) {
    name = props
    label = pascalCase(name)
    required = true
  } else if (typeofProps === "object") {
    const { name: _name, label: _label, type: _type, required: _required, ...rest } = props
    name = _name
    label = _label || pascalCase(_name)
    type = _type || "string"
    required = typeof _required === "boolean" ? _required : true
    otherProps = rest
  }

  return { required, name, label, type, ...otherProps }
}
export const titleLine = (
  title:
    | string
    | number
    | boolean
    | ReactElement<any, string | JSXElementConstructor<any>>
    | Iterable<ReactNode>
    | null
    | undefined,
) => {
  return {
    hidden: false,
    title,
    type: "object",
    name: title, //`__line__${generateUUID()}`,
    options: {
      customBodyRender: (value: any) => {
        return (
          <div className="classes-custom-prefix-MuiBox-root classes-custom-prefix-MuiBox-root-34">
            <h5 className="classes-custom-prefix-MuiTypography-root classes-custom-prefix-MuiTypography-h5">
              {title}
            </h5>
            <hr className="classes-custom-prefix-MuiDivider-root" />
          </div>
        )
      },
    },
  }
}

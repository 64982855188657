import { doc, updateDoc } from "firebase/firestore"

import { LoadingService } from "react-ui-components/dist/components"
import { db } from "../../../firebase"
import reduce from "lodash.reduce"

export async function updateData({ setLoading, data, onSuccess, path }: any) {
  try {
    LoadingService.setLoading(true, path)
    if (!path) {
      return console.error("Path is required to update.")
    }

    setLoading && setLoading(true)

    const ref = doc(db, typeof path === "string" ? path : path.split("/"))

    data = reduce(
      data,
      (result: { [x: string]: any }, value: any, key: string | number) => {
        result[key] = typeof result[key] === "undefined" ? "" : result[key]
        return result
      },
      data,
    )

    await updateDoc(ref, data)

    onSuccess && onSuccess(data)
  } catch (error) {
    console.error("There was an error updating or updating country", error)
  } finally {
    setLoading && setLoading(false)
    LoadingService.setLoading(true, path)
  }
}

import { ImageWidget } from "./ImageWidget"

export const uiSchema = ({ bucket, propName }: any) =>
  propName
    ? {
        // 'ui:field': 'image',
        [propName]: {
          "ui:widget": ImageWidget({ bucket }),
        },
      }
    : {}

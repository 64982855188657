import { FormControlLabel, Switch } from "@mui/material"

import React from "react"

export function disabledColumnItem() {
  return {
    name: "disabled",
    options: {
      filter: false,
      customBodyRender: (value: boolean | undefined, tableMeta: any, updateValue: any) => {
        return (
          <FormControlLabel
            label={value ? "Yes" : "No"}
            value={value ? "Yes" : "No"}
            control={
              <Switch disabled color="primary" checked={value} value={value ? "Yes" : "No"} />
            }
            onChange={(event: any) => {
              const val = event.target.value
            }}
          />
        )
      },
    },
  }
}

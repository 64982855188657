import {
  CitySelectWidget,
  CountrySelectWidget,
  DateTimeWidget,
  ImageWidget,
  LanguageDropdownWidget,
  SelectWidget,
  StateSelectWidget,
} from "./CustomWidgets"
import { DataProvider, ListComponent } from "../GenericListComponent"

import LocationSelector from "../LocationSelector"
// import { LocationCustomWidget } from "./LocationCustomWidget"
import React from "react"
import { useParams } from "react-router-dom"

const data = {
  getListLocation(id: any) {
    return ["sponsors", id, "services"]
  },
  EDIT_TITLE: "Edit Service",
  ADD_TITLE: "Add Sponsor",
  LOADING_MESSAGE: "Loading Service...",
  TITLE: "Service",
  IMAGES_BUCKET: "ServiceImages",

  onRowClick:
    (list: any, navigate: any) =>
    (arr: any, index: { dataIndex: any } /*: {rowIndex: number, dataIndex: number}*/) => {
      console.log("on row click----->", index.dataIndex)
      // const state = list[index.dataIndex]
      // const id = state.id
      // navigate(`/sponsors/${id}`, { state })
    },

  columnsProperties: [
    { hidden: true, name: "id", label: "Id" },
    { required: false, name: "disabled", label: "Disabled", type: "boolean" },
    {
      required: false,
      name: "expirationDate",
      label: "Expiration Date",
      type: "number",
      uiType: "datetime2",
    },

    { required: true, name: "name", label: "Name" },
    { required: true, name: "title", label: "Title" },
    { required: true, name: "linkUrl", label: "Link URL" },
    { required: true, type: "integer", name: "order", label: "Order" },
    { required: true, type: "string", name: "type", label: "Type" },
    { required: false, type: "number", name: "value", label: "Value" },
    { required: false, type: "string", name: "currency", label: "Currency" },

    {
      type: "string",
      name: "iconUrl",
      label: "Icon",

      options: {
        customBodyRender: (value: string | undefined) => {
          return (
            <div className="flex flex-col flex-wrap overflow-auto w-40">
              {!value ? null : <img src={value} />}
            </div>
          )
        },
      },
    },
    {
      type: "string",
      name: "image",
      label: "Image",
      options: {
        customBodyRender: (value: string | undefined) => {
          return (
            <div className="flex flex-col flex-wrap overflow-auto w-40">
              {!value ? null : <img src={value} />}
            </div>
          )
        },
      },
    },

    { required: false, name: "description", label: "Description" },
    { required: true, name: "language", label: "Language" },
    {
      required: false,
      name: "location",
      type: "object",
      properties: {
        country: { type: "string", title: "Country" },
        province: { type: "string", title: "State" },
        city: { type: "string", title: "City" },
        street: { type: "string", title: "Street" },
        county: { type: "string", title: "County" },
        zipCode: { type: "string", title: "Zip Code" },
      },
      options: {
        customBodyRender: (value: {
          [x: string]:
            | string
            | number
            | boolean
            | React.ReactElement<any, string | React.JSXElementConstructor<any>>
            | Iterable<React.ReactNode>
            | React.ReactPortal
            | Iterable<React.ReactNode>
            | null
            | undefined
        }) => {
          return (
            <div className="flex flex-col flex-wrap overflow-auto w-40">
              {!value
                ? null
                : Object.keys(value).map((k) => {
                    return (
                      <label>
                        <b>{k}:</b>
                        {value[k]}
                      </label>
                    )
                  })}
            </div>
          )
        },
      },
    },
  ],

  uiSchema: {
    iconUrl: {
      "ui:widget": "image",
    },
    image: {
      "ui:widget": "image",
    },
    // location: {
    //   "ui:widget": LocationCustomWidget,
    // },
    expirationDate: {
      "ui:widget": "datetime2",
    },
    description: {
      "ui:widget": "textarea",
    },
    language: {
      "ui:widget": LanguageDropdownWidget,
    },
    location: {
      country: {
        "ui:widget": CountrySelectWidget,
      },
      province: {
        "ui:widget": StateSelectWidget,
      },
      city: {
        "ui:widget": CitySelectWidget,
      },
    },
  },

  async imageProcessor(
    fromData: { iconUrl: any; image: any },
    handleUpload: (arg0: any, arg1: string, arg2: any) => any,
    id: any,
  ) {
    if (fromData.iconUrl) {
      fromData.iconUrl = await handleUpload(id, this.IMAGES_BUCKET, fromData.iconUrl)
    }

    if (fromData.image) {
      fromData.image = await handleUpload(id, this.IMAGES_BUCKET, fromData.image)
    }

    return fromData
  },
  widgets: { LocationSelector, SelectWidget, image: ImageWidget, datetime2: DateTimeWidget },
}

const Services = ({ onSuccess }: any) => {
  const { id } = useParams()
  return (
    <div>
      <DataProvider
        {...data}
        mainId={id}
        format={(data: {
          value: any
          disabled: any
          location: {
            country: any
            province: any
            city: any
            street: any
            county: any
            zipCode: any
          }
        }) => {
          return {
            ...data,
            value: data.value || "",
            disabled: !!data.disabled,
            location: {
              ...data.location,
              country: data.location.country || "*",
              province: data.location.province || "*",
              city: data.location.city || "*",
              street: data.location.street || "*",
              county: data.location.county || "*",
              zipCode: data.location.zipCode || "*",
            },
          }
        }}
      >
        <ListComponent onSuccess={onSuccess} />
      </DataProvider>
    </div>
  )
}
export default Services

import { Button, Grid, TextField } from "@mui/material"
import React, { useEffect, useState } from "react"
import { db, functions } from "../firebase"
import { doc, getDoc } from "firebase/firestore"

import { LoadingService } from "react-ui-components/dist/components";
import { Theme as MuiTheme } from "@rjsf/material-ui"
import { QRCodeCanvas } from "qrcode.react"
import { httpsCallable } from "firebase/functions"
import { sanitizeData } from "../utils"
import validator from "@rjsf/validator-ajv8"
import { withTheme } from "@rjsf/core"

const generateNewPrepayTickets = httpsCallable(functions, "generateNewPrepayTickets")

// const ticketSchema = {
//   title: "Ticket",
//   type: "object",
//   required: [
//     // "value",
//     //  "points",
//     // "createdBy", "createdDate",
//     // "expirationDate",
//     //  "id", "index",
//     // "order",
//     //  "raffleId"
//   ],
//   properties: {
//     // order: { type: "number", title: "Order" },
//     // disabled: { type: "boolean", title: "Disabled" },
//     // expirationDate: { type: "string", format: "date-time", title: "Expiration Date" },
//     value: { type: "number", title: "Value" },
//     points: { type: "number", title: "Points" },
//     // createdBy: { type: "string", title: "Created By" },
//     // createdDate: { type: "string", format: "date", title: "Created Date" },
//     // id: { type: "string", title: "ID" },
//     // index: { type: "number", title: "Index" },
//     // isSold: { type: "boolean", title: "Is Sold" },
//     // isWinner: { type: "boolean", title: "Is Winner" },
//     // raffleId: { type: "string", title: "Raffle ID" }
//   }
// };

const uiSchema = {
  // raffleDate: {
  //   "ui:widget": DateTimeWidget,
  // },
  userScanCount: {
    "ui:help": "Leave blank or zero for an infinite amount",
  },
}

// const [formValues, setFormValues] = useState({
//   ticketValue: "",
//   points: "",
//   createdBy: "",
//   createdDate: "",
//   disabled: false,
//   expirationDate: "",
//   id: "",
//   index: "",
//   isSold: false,
//   isWinner: false,
//   order: ""
// });

// const handleChange = (e) => {
//   const { name, value } = e.target;
//   setFormValues({ ...formValues, [name]: value });
// };

// const handleSubmit = async (e) => {
//   e.preventDefault();
//   try {
//     await addDoc(collection(db, `raffles/${raffleId}/tickets`), formValues);
//     alert("Ticket generated successfully!");
//   } catch (error) {
//     console.error("Error adding document: ", error);
//   }
// };

const ThemedForm = withTheme(MuiTheme)

const TicketForm = ({ raffleId, onSuccess }: any) => {
  const [ticketsCount, setTicketsCount] = useState(1)
  const [ticketTypes, setTicketTypes] = useState<any>([
    {
      name: "RafflePrepay",
      title: "Raffle Prepay",
    },
  ])
  const [loading, setLoading] = useState(true)
  const [ticketSchema, setTicketSchema] = useState(null)
  const [qrCode, setQrCode] = useState("")

  useEffect(() => {
    const fetchDisplayContentConfig = async () => {
      setLoading(true)
      const querySnapshot = await getDoc(doc(db, "app-configurations", "rafflesTicketsTypes"))
      const data = querySnapshot.data()?.collection
      if (!data) {
        return
      }
      setTicketTypes(data)

      setTicketSchema({
        title: "Ticket",
        type: "object",
        required: ["ticketTypes"],
        properties: {
          ticketTypes: {
            type: "array",
            title: "Ticket Types",
            items: {
              type: "string",
              enum: data.map((x: any) => x.title),
            },
            uniqueItems: true,
          },
          userScanCount: { type: "number", title: "User Scan Count" },
          value: { type: "number", title: "Value" },
          points: { type: "number", title: "Points" },
        },
        dependencies: {
          ticketTypes: {
            allOf: ticketTypes.map((type: any) => ({
              if: {
                properties: {
                  ticketTypes: { contains: { const: type.name } },
                },
              },
              then: {
                properties: {
                  [`${type.name.toLowerCase()}Property`]: {
                    type: "string",
                    title: `${type.name} Property`,
                  },
                },
              },
            })),
          },
        },
      } as any)

      setLoading(false)
    }
    fetchDisplayContentConfig()
  }, [])

  const handleSubmit = async ({ formData }: any) => {
    if (!raffleId) {
      alert("Raffle ID is required.")
      return
    }
    setQrCode("")

    const sanitizedData = sanitizeData(formData)
    // sanitizedData.expirationDate = sanitizedData.expirationDate ? new Date(sanitizedData.expirationDate).getTime() : null;
    const ticketData = { ...sanitizedData, raffleId }
    try {
      LoadingService.setLoading(true)
      const result: any = await generateNewPrepayTickets({
        raffleId,
        ticketsCount,
        ticketTypes: formData.ticketTypes,
        ticketsPointsValue: ticketData.value,
        ticketsPoints: ticketData.points,
        userScanCount: ticketData.userScanCount,
      })
      alert("Ticket generated successfully!")
      // console.log('generateNewPrepayTickets------>', result)
      setQrCode(result.data)
      onSuccess && onSuccess(result)
    } catch (error) {
      console.error("Error adding document: ", error)
    } finally {
      LoadingService.setLoading(false)
    }
  }

  if (!ticketSchema || loading || !ticketTypes?.length) {
    return <h1>Loading...</h1>
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <ThemedForm
          uiSchema={uiSchema}
          schema={ticketSchema}
          onSubmit={handleSubmit}
          validator={validator}
        >
          <div style={{ display: "flex", flexDirection: "row", gap: 5 }}>
            <TextField
              value={ticketsCount}
              label="Count"
              onChange={(event) => setTicketsCount(+event.target.value)}
            />
            <Button type="submit" variant="contained" color="primary">
              Generate Ticket
            </Button>
          </div>
        </ThemedForm>
      </Grid>
      {!qrCode ? null : (
        <Grid item xs={6}>
          <QRCodeCanvas value={qrCode} size={256} />
          <p className="flex flex-wrap">{qrCode}</p>
        </Grid>
      )}
    </Grid>
  )
}

export default TicketForm

import "react-quill/dist/quill.snow.css"

import { Avatar, Button, FormLabel, Tooltip } from "@mui/material"
import { CREATED_BY, CREATED_DATE, DISABLED, ID, NAME, getProp, titleLine } from "../CommonColumns"
import {
  CitySelectWidget,
  CountrySelectWidget,
  DateTimeWidget,
  ImageWidget,
  LocationSelectWidget,
  StateSelectWidget,
} from "../CustomWidgets"
import { DataProvider, ListComponent } from "../../GenericListComponent"
import { NumberField, TextField as TextFieldProps } from "../FormFields"
// RaffleTable.js
import React, { useEffect, useState } from "react"
import { raffleSchema, raffleUISchema } from "react-ui-components/dist/components/raffle"

import { ArrayImageWidget } from "../core/CreateEditOptions/ImageWidget"
import { CustomInputProps } from "../CustomInputProps"
import CustomObjectFieldTabs from "./CustomObjectFieldTabs";
// import { Button } from "@mui/material"
// import { ImageWidget } from "./components/CustomWidgets"
// import LoadingScreen from "react-loading-screen"
// import MUIDataTable from "mui-datatables"
// import { db } from "../firebase"
// import { useNavigate } from "react-router-dom"
import { DateTime } from "luxon"
import { Loading } from "react-ui-components/dist/components"
import { PopupService } from "react-ui-components/dist/components"
import ReactQuill from "react-quill"
import { Timestamp } from "firebase/firestore"
import { auth } from "../../firebase"
import { convertToMeasure } from "../../utils"
import { useAppConfiguration } from "../AppConfigurationService"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

// import { collection, getDocs } from "firebase/firestore"

const ticketDesign = (showEdit: any) => {
  return {
    name: "ticketDesign",
    type: "object",
    label: "Ticket Design",

    properties: {
      backgroundColor: { type: "string", title: "Background Color" },
      color: { type: "string", title: "Color" },
      height: { type: "number", title: "Height" },
      justifyContent: { type: "string", title: "Justify Content" },
      margin: { type: "number", title: "Margin" },
      padding: { type: "number", title: "Padding" },
      textColor: { type: "string", title: "Text Color" },
      width: { type: "number", title: "Width" },
    },
    options: {
      customBodyRender: (value: {
        backgroundColor: any
        textColor: any
        color: any
        height: any
        width: any
        padding: any
        margin: any
        justifyContent: any
        image?: string
      }) => {
        value = value || {
          textColor: "#000",
          color: "#000",
          justifyContent: "center",
          backgroundColor: "#FFF",
          margin: 5,
          image: "",
          height: 120,
          width: 180,
          padding: 5,
        }
        return (
          <div className="flex flex-col flex-wrap overflow-auto w-full h-40 overflow-hidden">
            <div
              onClick={(e) => e.stopPropagation()}
              className="flex flex-col overflow-hidden"
              style={{
                backgroundColor: value.backgroundColor,
                color: value.textColor || value.color,
                height: value.height,
                width: value.width,
                padding: `calc(${convertToMeasure(value.padding)} + 5px)`,
                margin: value.margin,
                justifyContent: value.justifyContent,

                borderWidth: 1,
                borderColor: "#DDD",
                borderRadius: 20,
              }}
            >
              <p>
                <b>TEST 1:</b> testing 1234
              </p>
              <p>
                <b>TEST 2:</b> testing 1234
              </p>
              <p>
                <b>TEST 3:</b> testing 1234
              </p>
            </div>
            {!showEdit ? null : (
              <button
                onClick={(e) => {
                  console.log(e)
                  e.stopPropagation()
                  PopupService.setPopup(
                    <DataProvider {...ticketDesignData}>
                      <ListComponent />
                    </DataProvider>,
                  )
                }}
              >
                Edit
              </button>
            )}
          </div>
        )
      },
    },
  }
}

const ticketDesignData = {
  onRowClick: (list: any, navigate: any) => {},
  getListLocation(id: any) {},

  EDIT_TITLE: "Edit Ticket Design",
  TITLE: "Ticket Design",
  IMAGES_BUCKET: "ticketDesignImages",
  columnsProperties: [ticketDesign(true)],

  uiSchema: {},

  async imageProcessor(
    fromData: { images: any[] },
    handleUpload: (arg0: any, arg1: string, arg2: any, arg3: { [x: string]: any }) => any,
    id: any,
  ) {
    if (fromData.images?.length > 0) {
      const imagesURLs = await Promise.all(
        fromData.images.map(({ image, ...rest }) => {
          return handleUpload(id, this.IMAGES_BUCKET, image, rest)
        }),
      )
      fromData.images = imagesURLs
    }

    return fromData
  },
}

/*
  createdBy(string)
  createdDate(number)
  description(string)
  disabled(boolean)
  height(number)
  id(string)
  lastTicketIndex(number)
  name(string)
  order(number)
  points(number)
  province(string)
  raffleDate(number)
  raffleImages(array)-(string)
  raffleLocation(map)
  
  subtitle(string)
  termsText(string)
  ticketDesign(null)
  title(string)
  type(string)
  value(number)
  */
/*
        disabled: types.optional(types.boolean, false),
        name: types.optional(types.string, ""),
        city: types.optional(types.string, "*"),
        country: types.optional(types.string, "*"),
        province: types.optional(types.string, "*"),
        county: types.optional(types.string, "*"),
        street: types.optional(types.string, "*"),
        info: types.optional(types.string, ""),
        image: types.optional(types.string, ""),
        phone: types.optional(types.string, ""),
        houseNumber: types.optional(types.string, ""),
        postcode: types.optional(types.string, ""),
  */

const Raffles = () => {
  const [data, setData] = useState<any>(null)
  const { configuration } = useAppConfiguration()
  const { t } = useTranslation()

  useEffect(() => {
    setData({
      widgets: {
        DateTimeWidget,
      },
      onRowClick: (
        list: { [x: string]: any },
        navigate: (arg0: string, arg1: { state: any }) => void,
      ) => {
        return (_rowData: any, rowMeta: { dataIndex: string | number }) => {
          const state = list[rowMeta.dataIndex]
          const id = state.id
          navigate(`/raffles/${id}`, { state })
        }
      },
      getListLocation(id: any) {
        return ["raffles"]
      },

      EDIT_TITLE: "Edit Raffle",
      ADD_TITLE: "Add Raffle",
      LOADING_MESSAGE: "Loading raffles...",
      TITLE: "Raffles",
      IMAGES_BUCKET: "rafflesImages",

      columnsProperties: [
        ID,
        CREATED_DATE,
        CREATED_BY,
        DISABLED,
        {
          hidden: false,
          type: "string",
          required: true,
          name: "type",
          label: "Type",
          title: "Type Title",
          enum: configuration.raffleTypes,
        },
        getProp("title", "string"),
        getProp("subtitle", "string"),
        getProp("description", "string"),
        getProp("termsText", "string"),
        getProp("termsUrl", "string"),
        getProp("videoUrl", "string"),
        getProp("youtubeUrl", "string"),
        getProp("externalId", "string"),
        getProp("externalIdType", "string"),

        getProp("height", "number"),
        getProp({
          name: "winTicketPerLike",
          label: "Win Ticket Per Like",
          required: false,
          type: "boolean",
        }),
        {
          required: true,
          type: "number",
          name: "raffleDate",
          label: "Raffle Date",
          options: {
            customBodyRender: (value: number) => {
              if (typeof value === "number") {
                return DateTime.fromMillis(value).toLocaleString(DateTime.DATETIME_FULL)
              }
              return value
            },
          },
        },
        getProp({
          required: true,
          name: "raffleImages",
          type: "array",
          items: {
            type: "string",
          },
          options: {
            customBodyRender: (val: string[]) => {
              return (
                <div className="flex flex-row gap-1">
                  {val?.map((image: string, index: number) => (
                    <Avatar key={index} variant="rounded" sizes="sm" src={image} />
                  )) || "[NO RAFFLE IMAGES]"}
                </div>
              )
            },
          },
        }),
        titleLine("Business Info"),
        NAME,
        getProp({ name: "order", type: "number" }),
        getProp({ name: "country", required: false }),

        titleLine("Facil Coins"),
        getProp("value", "number"),
        getProp("points", "number"),
        getProp({
          name: "location",
          required: false,
          type: "object",
          properties: {
            country: { type: "string", title: "Country" },
            province: { type: "string", title: "Province" },
            city: { type: "string", title: "City" },
          },

          options: {
            customBodyRender: (value: any) => {
              return (
                <div className="flex flex-col flex-wrap overflow-auto w-40">
                  <p>{JSON.stringify(value)}</p>
                  <button>Edit</button>
                </div>
              )
            },
          },
        }),
        getProp({
          name: "raffleLocation",
          required: false,
          type: "object",
          properties: {
            disabled: { type: "boolean", title: "Disabled" },
            name: { type: "string", title: "Name" },
            country: { type: "string", title: "Country" },
            province: { type: "string", title: "Province" },
            city: { type: "string", title: "City" },
            street: { type: "string", title: "Street" },
            info: { type: "string", title: "Info" },
            phone: { type: "string", title: "Phone" },
            postcode: { type: "string", title: "Postal Code" },
            houseNumber: { type: "string", title: "Location #" },
          },

          options: {
            customBodyRender: (value: any) => {
              return (
                <div className="flex flex-col flex-wrap overflow-auto w-40">
                  <p>{JSON.stringify(value)}</p>
                  <button>Edit</button>
                </div>
              )
            },
          },
        }),

        {
          name: "tickets",
          required: false,
          label: "Tickets",
          hideEdit: true,
          options: {
            customBodyRender: (val: any, e: { rowData: any[] }) => {
              const id = e?.rowData?.[0]
              return <GotoGenerateTicketButton id={id} />
            },
          },
        },
        { hidden: true, name: "value", label: "Value", type: "number" },
        { name: "canDelete", label: "Can Be Deleted", value: true, type: "boolean" },

        { ...ticketDesign(true) },
      ],
      schema: raffleSchema,
      uiSchema: raffleUISchema,
      ObjectFieldTemplate: CustomObjectFieldTabs,
      // fields: {},

      async imageProcessor(
        fromData: { raffleImages: string[] },
        handleUpload: (formData: any, handleUpload: any, id: string, mainId?: string) => any,
        id: any,
      ) {
        if (fromData.raffleImages?.length > 0) {
          const imagesURLs = await Promise.all(
            fromData.raffleImages.map((image: string) => {
              /*
                id: any,
                fieldName: any,
                image: string,
                imageInfo: any,
                imagePropName: any,
              */
              return handleUpload(id, this.IMAGES_BUCKET, image)
            }),
          )
          fromData.raffleImages = imagesURLs
        }

        return fromData
      },
    })
  }, [])
  if (data === null) {
    return <Loading />
  }

  return (
    <DataProvider {...data}>
      <ListComponent
        defaultAddData={{
          type: configuration?.raffleTypes?.[0] || "Raffle",
          canDelete: true,
          disabled: false,
          winTicketPerLike: false,
          createdDate: Timestamp.now().toMillis(),
          createdBy: auth.currentUser?.uid || "",
          points: 0,
          value: 0,
          raffleLocation: {
            disabled: true,
            name: "",
            country: "",
            province: "",
            city: "",
            street: "",
            info: "",
            phone: "",
            postcode: "",
            houseNumber: "",
          },
          location: {
            country: "",
            province: "",
            city: "",
          },
          order: 1,
        }}
      />
    </DataProvider>
  )
}

export default Raffles

function GotoGenerateTicketButton({ id }: any) {
  const navigate = useNavigate()

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={(e) => {
        e.stopPropagation()
        navigate(`/raffles/${id}/generate-tickets`)
      }}
    >
      Generate Ticket
    </Button>
  )
}

export const schema = ({ title }: any) => {
  return {
    title: title,
    type: "object",
    required: ["name", "order"],
    properties: {
      disabled: { type: "boolean", title: "Disabled" },
      order: { type: "number", title: "Order" },
      name: { type: "string", title: "Page name" },
      description: { type: "string", title: "Description" },
      background: {
        type: "string",
        title: "Background Image Url",
        cType: "image",
      },
    },
  }
}

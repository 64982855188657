import { Card, CardContent, Typography } from "@mui/material"
import { DocumentData, DocumentReference, getDoc } from "firebase/firestore"
import React, { useEffect, useState } from "react"

import { useTranslation } from "react-i18next"

export interface VideoData {
  title: string
  description: string
  videoUrl: string
  order: number
}

const VideoPlayer: React.FC<{
  docRef?: DocumentReference<DocumentData, DocumentData>
  video: VideoData | null
  center?: boolean
}> = ({ docRef = null, video = null, center = true }) => {
  const [videoData, setVideoData] = useState<VideoData | null>(video)
  const { t } = useTranslation()

  useEffect(() => {
    setVideoData(video)
  }, [video])

  useEffect(() => {
    const fetchVideoData = async () => {
      try {
        if (!docRef) {
          return console.warn("invalid docRef")
        }
        const docSnap = await getDoc(docRef)
        if (docSnap.exists()) {
          setVideoData(docSnap.data() as VideoData)
        } else {
          console.error("No such document!")
        }
      } catch (error) {
        console.error("Error fetching video data:", error)
      }
    }

    fetchVideoData()
  }, [docRef])

  if (!videoData) {
    return <div className="text-center text-accent">{t("Loading")}...</div>
  }

  return (
    <div
      className={`flex flex-col ${
        center ? "items-center justify-center" : ""
      } min-h-screen bg-futuristic text-white w-full`}
    >
      <Card className="w-11/12 md:w-2/3 lg:w-1/2 bg-gradient-to-br from-futuristic via-secondary to-accent shadow-2xl">
        <CardContent>
          {!videoData.title ? null : (
            <Typography variant="h4" component="h1" className="text-center mb-4">
              {videoData.title}
            </Typography>
          )}
          <div className="video-container relative w-full h-0 pb-[56.25%] mb-4">
            {!videoData.videoUrl ? null : (
              <iframe
                className="absolute top-0 left-0 w-full h-full rounded-md"
                src={videoData.videoUrl}
                title={videoData.title}
                allowFullScreen
              ></iframe>
            )}
          </div>
          {!videoData.description ? null : (
            <Typography variant="body1" className="text-center">
              {videoData.description}
            </Typography>
          )}
        </CardContent>
      </Card>
    </div>
  )
}

export default VideoPlayer

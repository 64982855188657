import { doc, setDoc } from "firebase/firestore"

import AdminPanel from "./AdminPanel"
import React from "react"
import { db } from "../firebase"
import { useState } from "react"

const saveConfiguration = async (config: any) => {
  const docRef = doc(db, "loginPage", "configuration")
  await setDoc(docRef, config)
}

const AdminPanelConfig = () => {
  const [components, setComponents] = useState<any>([])

  const handleSave = () => {
    saveConfiguration({ components })
  }

  return (
    <div>
      {/* <AdminPanel components={components} setComponents={setComponents} /> */}
      <button onClick={handleSave} className="mt-4 btn btn-primary">
        Save Configuration
      </button>
    </div>
  )
}

export default AdminPanelConfig

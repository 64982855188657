import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material"
import {
  DocumentData,
  QueryDocumentSnapshot,
  collection,
  endAt,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  startAt,
  where,
} from "firebase/firestore"
import React, { useEffect, useState } from "react"
import { db, functions } from "../../firebase"
import { faImage, faPaperPlane } from "@fortawesome/free-solid-svg-icons"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import MUIDataTable from "mui-datatables"
import { httpsCallable } from "firebase/functions"
import { motion } from "framer-motion"

const sendNotificationsEx = httpsCallable(functions, "sendNotificationsEx")

interface User {
  id: string
  fcmWebToken: string
  pushNotificationToken: string
  email: string
  displayName: string
}

const AdminNotificationsPage: React.FC = () => {
  const [users, setUsers] = useState<User[]>([])
  const [selectedUsers, setSelectedUsers] = useState<string[]>([])
  const [notificationTitle, setNotificationTitle] = useState("")
  const [notificationBody, setNotificationBody] = useState("")
  const [notificationName, setNotificationName] = useState("")
  const [notificationImage, setNotificationImage] = useState<File | null>(null)
  const [deviceType, setDeviceType] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [isSending, setIsSending] = useState(false)
  const [lastVisible, setLastVisible] = useState<QueryDocumentSnapshot<DocumentData> | null>(null)
  const [hasMore, setHasMore] = useState(true)
  const [searchQuery, setSearchQuery] = useState("")

  const USERS_PAGE_SIZE = 10

  const fetchUsers = async (reset = false, search = "") => {
    setIsLoading(true)
    try {
      let userQuery
      if (search) {
        userQuery = query(
          collection(db, "users"),
          where("pushNotifications", "==", true),
          orderBy("displayName"),
          startAt(search),
          endAt(search + "\uf8ff"),
          limit(USERS_PAGE_SIZE),
        )
      } else {
        userQuery = reset
          ? query(
              collection(db, "users"),
              where("pushNotifications", "==", true),
              orderBy("displayName"),
              limit(USERS_PAGE_SIZE),
            )
          : query(
              collection(db, "users"),
              where("pushNotifications", "==", true),
              orderBy("displayName"),
              startAfter(lastVisible),
              limit(USERS_PAGE_SIZE),
            )
      }

      const snapshot = await getDocs(userQuery)
      const usersData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as User[]

      if (reset) {
        setUsers(usersData)
      } else {
        setUsers((prevUsers) => [...prevUsers, ...usersData])
      }

      setLastVisible(snapshot.docs[snapshot.docs.length - 1])
      setHasMore(usersData.length === USERS_PAGE_SIZE)
    } catch (error) {
      console.error("Error fetching users:", error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchUsers(true)
  }, [])

  const handleTableChange = (action: any, tableState: any) => {
    if (action === "rowSelectionChange") {
      const selectedData = tableState.selectedRows.data.map((row: any) => users[row.index].id)
      setSelectedUsers(selectedData)
    } else if (action === "search" && tableState.searchText !== searchQuery) {
      fetchUsers(true, tableState.searchText)
    }
  }

  const handleSendNotification = async () => {
    if (!notificationTitle || !notificationBody || selectedUsers.length === 0) {
      alert("Please fill out all fields and select at least one user.")
      return
    }

    const notificationData = {
      title: notificationTitle,
      body: notificationBody,
      customData: {},
      androidChannel: "default",
      soundEnabled: true,
      appleBadge: 1,
    }

    setIsSending(true)

    try {
      const response = await sendNotificationsEx({
        userIds: selectedUsers,
        deviceTypes: deviceType,
        notificationData,
      })

      //   fetch('/sendNotifications', {
      //     method: 'POST',
      //     headers: {
      //       'Content-Type': 'application/json',
      //     },
      //     body: JSON.stringify({
      //       userIds: selectedUsers,
      //       deviceTypes: deviceType,
      //       notificationData,
      //     }),
      //   });

      alert(`Notifications sent: ${response}`)
    } catch (error) {
      console.error("Failed to send notifications:", error)
      alert("Failed to send notifications.")
    } finally {
      setIsSending(false)
    }
  }

  const columns = [
    { name: "displayName", label: "Name" },
    { name: "email", label: "Email" },
    { name: "id", label: "User ID" },
  ]

  const options = {
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    selectableRows: "multiple",
    selectableRowsOnClick: true,
    onTableChange: handleTableChange,
    search: true,
    searchPlaceholder: "Search users",
  }

  return (
    <Box p={3}>
      <Typography variant="h4" gutterBottom>
        Send Notifications
      </Typography>

      <MUIDataTable title="Select Users" data={users} columns={columns} options={options} />

      <Box mt={2}>
        <TextField
          label="Notification Name"
          value={notificationName}
          onChange={(e) => setNotificationName(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Notification Title"
          value={notificationTitle}
          onChange={(e) => setNotificationTitle(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Notification Body"
          value={notificationBody}
          onChange={(e) => setNotificationBody(e.target.value)}
          fullWidth
          multiline
          rows={4}
          margin="normal"
        />

        <input
          accept="image/*"
          id="upload-notification-image"
          type="file"
          style={{ display: "none" }}
          onChange={(e) => setNotificationImage(e.target.files?.[0] || null)}
        />
        <label htmlFor="upload-notification-image">
          <Button
            variant="outlined"
            component="span"
            startIcon={<FontAwesomeIcon icon={faImage} />}
            sx={{ mt: 2 }}
          >
            Upload Image
          </Button>
        </label>

        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel id="device-type-label">Device Type</InputLabel>
          <Select
            labelId="device-type-label"
            multiple
            value={deviceType}
            onChange={(e) => setDeviceType(e.target.value as string[])}
          >
            <MenuItem value="Android">Android</MenuItem>
            <MenuItem value="iOS">iOS</MenuItem>
            <MenuItem value="Web">Web</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<FontAwesomeIcon icon={faPaperPlane} />}
          onClick={handleSendNotification}
          disabled={isSending}
          fullWidth
          sx={{ mt: 3 }}
        >
          {isSending ? <CircularProgress size={24} /> : "Send Notification"}
        </Button>
      </motion.div>
    </Box>
  )
}

export default AdminNotificationsPage

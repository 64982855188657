/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { Button, Input } from "@mui/material"
import React, { useState } from "react"

import { Add } from "@mui/icons-material"
import { Label } from "@fluentui/react"
import UploadImage from "../../../utils/UploadImage"

export const ImageWidget =
  ({ bucket }: any) =>
  (props: any) => {
    return (
      <>
        <Label>{props.label}</Label>
        <Input value={props.value} onChange={(e) => props.onChange(e.target.value)} />
        <UploadImage
          url={props.value}
          bucket={bucket}
          getUrl={(url: any) => {
            props.onChange(url)
          }}
        />
      </>
    )
  }

export const ArrayImageWidget =
  ({ bucket }: any) =>
  (props: any) => {
    const [arr, setArr] = useState<string[]>(props.value || [])

    return (
      <>
        <Label>{props.label}</Label>
        <div>
          <Button
            endIcon={<Add />}
            onClick={() => {
              setArr((v) => ["", ...v])
            }}
          />
          {arr.map((item, i) => {
            return (
              <div key={i}>
                <Input value={item} onChange={(e) => props.onChange(e.target.value)} />
                <UploadImage
                  url={item}
                  bucket={bucket}
                  getUrl={(url: any) => {
                    props.onChange(url)
                  }}
                />
              </div>
            )
          })}
        </div>
      </>
    )
  }

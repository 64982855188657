import { DataProvider, ListComponent } from "./GenericListComponent"

import { ImageWidget } from "./components/CustomWidgets"
import React from "react"
import { SampleImageRenderer } from "./SampleImageRenderer"

const data = {
  getListLocation(id: any) {
    return ["businesses", id, "offers"]
  },

  EDIT_TITLE: "Edit Offer",
  ADD_TITLE: "Add Offer",
  LOADING_MESSAGE: "Loading offers...",
  TITLE: "Business Offers",
  IMAGES_BUCKET: "offersImages",

  columnsProperties: [
    { hidden: true, name: "id", label: "Id" },
    { required: false, name: "disabled", label: "Disabled", type: "boolean" },

    { required: true, name: "expirationDate", label: "Expiration Date" },
    { required: true, name: "name", label: "Name" },
    { required: true, name: "order", label: "Order" },
    {
      hidden: false,
      type: "string",
      required: true,
      name: "icon",
      label: "Icon",
      options: {
        customBodyRender: SampleImageRenderer(),
      },
    },
    {
      hidden: false,
      type: "array",
      required: false,
      name: "images",
      label: "Images",
      options: {
        customBodyRender: SampleImageRenderer(),
      },
      items: {
        type: "object",
        properties: {
          title: {
            type: "string",
            title: "Title",
          },
          link: {
            type: "string",
            title: "Link",
          },
          image: {
            type: "string",
            title: "Image",
          },
        },
      },
    },
    { required: true, name: "description", label: "Description" },
    { required: true, name: "language", label: "Language" },
    { required: true, name: "country", label: "Country" },
    { required: false, name: "state", label: "State" },
    { required: false, name: "city", label: "City" },
    { required: false, name: "street", label: "Street" },
    { required: false, name: "county", label: "County" },
    { required: false, name: "zipCode", label: "Zip Code" },
  ],

  uiSchema: {
    icon: {
      "ui:widget": ImageWidget,
    },
    images: {
      items: {
        image: {
          "ui:widget": ImageWidget,
        },
      },
    },
    expirationDate: {
      "ui:widget": "datetime",
    },

    description: {
      "ui:widget": "textarea",
    },
  },

  async imageProcessor(fromData: { images: any[]; }, handleUpload: (arg0: any, arg1: string, arg2: any, arg3: { [x: string]: any; }) => any, id: any) {
    if (fromData.images?.length > 0) {
      const imagesURLs = await Promise.all(
        fromData.images.map(({ image, ...rest }) => {
          return handleUpload(id, this.IMAGES_BUCKET, image, rest)
        }),
      )
      fromData.images = imagesURLs
    }

    return fromData
  },
}

export const BusinessOffersComponent = ({ businessId }: any) => {
  return (
    <DataProvider {...data} mainId={businessId}>
      <ListComponent />
    </DataProvider>
  )
}

// import {Form, FormEdit, FormGrid, Grid} from '@formio/react';
import React, { useEffect } from "react"

import { DataTable } from "../core/CreateEditOptions/DataTable"
import { getColumns } from "./getColumns"
import { getData } from "../core/CreateEditOptions/getData"
import getMap from "./getMap"
import { options } from "./options"
import { schema } from "./schema"
import update from "../core/CreateEditOptions/update"
import { useState } from "react"

const PATH = "app-configurations/translations"
const BUCKET = "assets"
const TITLE = "Translations"
const ADD_TITLE = "Add new Translation"

const Main = () => {
  const [data, setData] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const [columns, setColumns] = useState<any>([])
  useEffect(() => {
    if (!data?.length) {
      getData({ setData, path: PATH, getMap, setLoading })
    }
  }, [])

  useEffect(() => {
    if (!data?.length) {
      return
    }
    setColumns(
      getColumns({
        setLoading,
        setData,
        path: PATH,
        onEditSuccess: async ({ result, close, key }: any) => {
          await update({
            path: PATH,
            key,
            setLoading,
            data: result.formData,
          })

          getData({ setData, path: PATH, getMap, setLoading })
          // updateData(data, 'key');
          // data && setData(data);
          close()
        },
      }),
    )
  }, [data])

  // if (!data || !Array.isArray(data)) {
  //   setData([]);
  // }

  return (
    <DataTable
      isCollection={false}
      path={PATH}
      bucket={BUCKET}
      title={TITLE}
      addTitle={ADD_TITLE}
      setLoading={setLoading}
      setData={setData}
      getMap={getMap}
      loading={loading}
      data={data}
      columns={columns}
      options={options}
      schema={schema}
      onEditSuccess={(data: any) => {
        getData({ setData, path: PATH, getMap })
      }}
      onAddSuccess={async ({ result, close }: any) => {
        const { key } = result.formData
        await update({
          path: PATH,
          key,
          setLoading,
          data: result.formData,
        })

        getData({ setData, path: PATH, getMap })
        close()
      }}
    />
  )
}

export default Main

export const schema = ({ title }: any) => {
  return {
    title: title,
    type: "object",
    required: ["key"],
    properties: {
      disabled: { type: "boolean", title: "Disabled" },
      key: { type: "string", title: "Language Key" },
    },
  }
}

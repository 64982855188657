// import {Form, FormEdit, FormGrid, Grid} from '@formio/react';
import React, { useEffect } from "react"

import { DataTable } from "../core/CreateEditOptions/DataTable"
import { getColumns } from "./getColumns"
import { getData } from "../core/CreateEditOptions/getData"
import getMap from "./getMap"
import { options } from "./options"
import { schema } from "./schema"
import update from "../core/CreateEditOptions/update"
import { useState } from "react"

const PATH = "app-configurations/countries"
const BUCKET = "assets"
const TITLE = "Countries"
const ADD_TITLE = "Add new Country"
const IMAGE_PROP_NAME = "icon"

const Main = () => {
  const [data, setData] = useState<any>()
  const [loading, setLoading] = useState()
  useEffect(() => {
    getData({ setData, path: PATH, getMap })
  }, [])

  const columns = getColumns({
    setLoading,
    setData,
    path: PATH,
    onEditSuccess: async ({ result, close, key }: any) => {
      await update({
        path: PATH,
        key,
        setLoading,
        data: result.formData,
      } as any)

      getData({ setData, path: PATH, getMap })
      // updateData(data, 'key');
      // data && setData(data);
      close()
    },
  })
  if (!data || !Array.isArray(data)) {
    setData([])
  }

  return (
    <DataTable
      imagePropName={IMAGE_PROP_NAME}
      path={PATH}
      bucket={BUCKET}
      title={TITLE}
      addTitle={ADD_TITLE}
      setLoading={setLoading}
      setData={setData}
      getMap={getMap}
      loading={loading}
      data={data}
      columns={columns}
      options={options}
      schema={schema}
      onAddSuccess={async ({ result, close }: any) => {
        const { disabled, key, display, icon, order } = result.formData
        await update({
          path: PATH,
          setLoading,
          data: {
            [key]: {
              disabled: !!disabled,
              display,
              icon,
              order,
              key,
            },
          },
        } as any)

        getData({ setData, path: PATH, getMap })
        close()
      }}
    />
  )
}

export default Main

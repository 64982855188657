import React, { useState } from "react"
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage"

import { Input } from "@mui/material"
import { storage } from "../firebase"

function UploadImage({ bucket, getUrl, url }: any) {
  const [imgUrl, setImgUrl] = useState(url)
  const [progresspercent, setProgresspercent] = useState(0)
  const handleSubmit = (e: any) => {
    e.preventDefault()
    const file = e.target?.files[0]

    if (!file) return

    const storageRef = ref(storage, `${bucket}/${file.name}`)
    const uploadTask = uploadBytesResumable(storageRef, file)

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
        setProgresspercent(progress)
      },
      (error) => {
        alert(error)
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setImgUrl(downloadURL)
          getUrl && getUrl(downloadURL)
        })
      },
    )
  }
  return (
    <div>
      {/* <form onSubmit={handleSubmit} className="form">
        <input type="file" />
        <button type="submit">Upload</button>
      </form> */}
      <Input onChange={handleSubmit} type="file" name="file" />
      {!imgUrl && (
        <div className="outerbar">
          <div className="innerbar" style={{ width: `${progresspercent}%` }}>
            {progresspercent}%
          </div>
        </div>
      )}
      {imgUrl && <img src={imgUrl} alt="uploaded file" height={200} />}
    </div>
  )
}
export default UploadImage

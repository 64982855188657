import { column2object } from "./column2object"
import { disabledColumnItem } from "../core/disabledColumnItem"
import { editColumn } from "../core/CreateEditOptions/editColumn"
import { schema } from "./schema"

export function getColumns({
  setLoading,
  setData,
  path,
  onEditSuccess,
  imagePropName,
  tableMeta,
  data,
}: any) {
  return [
    {
      name: "path",
      options: {
        display: false,
        viewColumns: false,
      },
    },
    editColumn({
      dataRows: data,
      setData,
      imagePropName,
      path,
      setLoading,
      onEditSuccess,
      schema,
      column2object,
    } as any),
    disabledColumnItem(),
    "name",
    "background",
    "description",
    {
      name: "order",
      options: {
        sort: true,
      },
    },
  ]
}

/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */

export const schema = ({ title }: any) => {
  return {
    title: title,
    type: "object",
    required: ["display", "icon"],
    properties: {
      disabled: { type: "boolean", title: "Disabled" },
      order: { type: "number", title: "Order" },
      key: { type: "string", title: "Key" },
      display: { type: "string", title: "Display" },
      icon: { type: "string", title: "Icon Url" },
    },
  }
}

import { TableCell, TableRow } from "@mui/material"
import { db, functions } from "../../firebase"
import { deleteDoc, deleteField, doc, updateDoc, writeBatch } from "firebase/firestore"
import { memo, useCallback, useEffect, useRef, useState } from "react"

import { DataTable } from "../core/CreateEditOptions/DataTable"
import React from "react"
// import { SingleObject } from "../core/SingleObject";
// import add from "../core/CreateEditOptions/add";
// import { column2object } from "./column2object";
// import { contentSchema } from "./contentSchema";
// import { disabledColumnItem } from "../core/disabledColumnItem";
// import { editColumn } from "../core/CreateEditOptions/editColumn";
// import getMap from "./getMap"
// import { getColumns } from "./getColumns";
import { getData } from "../core/CreateEditOptions/getData"
import { httpsCallable } from "firebase/functions"

// import { imageSchema } from "./imageSchema";
// import update from "../core/CreateEditOptions/update";
// import { updateData } from "../core/CreateEditOptions/updateData";

const updateRoles = httpsCallable(functions, "updateRoles")

const PATH = "users"
const BUCKET = "users/assets"
const TITLE = "Roles"
const ADD_TITLE = "Add new Role"
const IMAGE_PROP_NAME = "profileImageUrl"

export const options = ({ path, data, onSuccess, ...rest }: any) => {
  return {
    // filterType: 'dropdown',
    responsive: "standard",
    selectableRows: "single",
    expandableRows: true,
    renderExpandableRow: (rowData: any, rowMeta: { dataIndex: string | number }) => {
      const obj = data[rowMeta.dataIndex]
      // const content = obj.content || {}
      // const image = obj.image || {}
      // const id = obj.id

      // console.log("DATA----->", { obj, rowData, rowMeta })

      return <ChildGrid uid={obj.uid} />
    },
    onRowsDelete: async (rowsDeleted: { data: any[] }, rowData: any, newTableData: any) => {
      console.log({ rowsDeleted, rowData, newTableData })

      const cityRef = doc(db, typeof path === "string" ? path : path.join("/"))

      // Get a new write batch
      // const batch = writeBatch(db);

      rowsDeleted.data.forEach(async (element: { dataIndex: string | number }) => {
        const row = data[element.dataIndex]
        const key = row ? row.key : ""
        const laRef = doc(db, path.join("/"))
        await updateDoc(cityRef, {
          [key]: deleteField(),
        })
      })

      onSuccess && onSuccess(data)

      // await updateDoc(cityRef, {
      //   [key]: deleteField(),
      // });

      // Commit the batch
      // await batch.commit();

      return false
    },
    ...rest,
  }
}
const ROLES_OPTIONS = [
  {
    title: "User",
    type: "boolean",
    default: false,
  },
  {
    title: "Vendor",
    type: "boolean",
    default: false,
  },
  {
    title: "Admin",
    type: "boolean",
    default: false,
  },
  {
    title: "Super",
    type: "boolean",
    default: false,
  },
]
const getMap = (item: any) => {
  console.log("GET_MAP----->", item)
  return item
}

const ChildGrid = function ChildGrid({ uid }: any) {
  const [data, setData] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const [columns, setColumns] = useState<any>(["name"])
  const path = `users/${uid}/roles`

  const loadData = useCallback(async () => {
    // console.log("getData------>", "loadData")
    await getData({
      setData,
      path,
      getMap,
      isCollection: true,
      filterFunction: (item: { enabled: any }) => !item.enabled,
    })
  }, [path])

  const prevUid = useRef(null)

  useEffect(() => {
    if (prevUid.current !== uid) {
      // console.log("uid----->", uid)
      prevUid.current = uid
      loadData()
    }
  }, [prevUid, uid, loadData])

  if (!data || !Array.isArray(data)) {
    setData([])
  }

  return (
    <tr>
      <div style={{ marginLeft: 30, marginTop: 10 }}>
        <DataTable
          isCollection
          imagePropName={IMAGE_PROP_NAME}
          path={`users/${uid}/roles`}
          bucket={BUCKET}
          title={TITLE}
          addTitle={ADD_TITLE}
          setLoading={setLoading}
          setData={setData}
          getMap={getMap}
          loading={loading}
          data={data}
          columns={columns}
          options={{
            pagination: false,
            tableBodyHeight: "100%",
            filter: false,
            viewColumns: false,
            search: false,
            print: false,
            download: false,
            onRowsDelete: (rowsDeleted: { data: any[] }, rowData: any, newTableData: any) => {
              console.log("rowsDeleted---->", { rowsDeleted, rowData, newTableData })

              updateRoles({
                uid,
                roles: rowsDeleted.data.map((item: { dataIndex: string | number }) => ({
                  ...data[item.dataIndex],
                  enabled: false,
                })),
              }).then(() => loadData())

              // // Get a new write batch
              // // const batch = writeBatch(db);

              // rowsDeleted.data.forEach(async (element) => {
              //   const row = data[element.dataIndex];
              //   const key = row.name;
              //   const ref = doc(db, ...`${path}/${key}`.split("/"));
              //   // const laRef = doc(db, ...path)
              //   await deleteDoc(ref);
              //   // await updateDoc(ref, {
              //   //   [key]: deleteField(),
              //   // })
              // });

              return false
            },
          }}
          schema={{
            title: "Add",
            type: "object",
            properties: {
              name: {
                type: "array",
                title: "Role name",
                items: ROLES_OPTIONS,
              },
            },
          }}
          onSuccess={(arg: any) => {
            console.log("what is this", arg)
            // getData({
            //   setData,
            //   path: PATH,
            //   getMap,
            //   isCollection: true,
            //   setLoading,
            //   filterFunction,
            // })
          }}
          onEditSuccess={(arg: any) => {
            console.log("what is this", arg)
            // getData({
            //   setData,
            //   path: PATH,
            //   getMap,
            //   isCollection: true,
            //   setLoading,
            //   filterFunction,
            // })
          }}
          onAddSuccess={async ({ result, close }: any) => {
            const mappedData = result.formData.name
              .map((item: any, i: number) => ({
                enabled: item,
                name: ROLES_OPTIONS[i].title,
              }))
              .filter((x: { enabled: any }) => x.enabled)

            console.log("ON-ADD----->", { mappedData, roles: result.formData.name })

            await updateRoles({
              uid,
              roles: mappedData,
            })
            // mappedData.forEach(async (item) => {
            //   await update({
            //     path: `users/${uid}/roles/${item.name}`,
            //     // key: item.name,
            //     setLoading,
            //     data: item,
            //     setOrUpdate: true,
            //   })
            // })
            await loadData()
            close()
          }}
        />
      </div>
    </tr>
  )
}

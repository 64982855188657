import { PATH, insertSpaces, sendPasswordReset, updateUser } from "./Main"
import React, { useEffect, useState } from "react"
import { collection, getDocs } from "firebase/firestore"
import { db, functions } from "../../firebase"

import Button from "@mui/material/Button"
// import Fab from "@mui/material/Fab"
import Form from "@rjsf/material-ui"
import FormControlLabel from "@mui/material/FormControlLabel"
import Grid from "@mui/material/Grid"
import { LoadingService } from "react-ui-components/dist/components";
import Switch from "@mui/material/Switch"
import { httpsCallable } from "firebase/functions"
import { updateData } from "../core/CreateEditOptions/updateData"
import validator from "@rjsf/validator-ajv8"

const sendNotifications = httpsCallable(functions, "sendNotifications")

async function getUserTickets(
  user: { uid: any; ticketsCount: any },
  setSelectedRowData: (arg0: (d: any) => any) => void,
) {
  if (!user) {
    return
  }
  try {
    const userId = user.uid
    if (!userId) {
      throw new Error("Invalid user. User is missing id or uid")
    }
    if (user.ticketsCount) {
      return
    }
    const ticketsRef = await getDocs(collection(db, "users", userId, "tickets"))
    const ticketsCount = ticketsRef.size
    setSelectedRowData((d: any) => {
      return { ticketsCount, ...d }
    })
  } catch (error: any) {
    alert(error.message || error)
  }
}
export function UserDetail({ close, selectedRowData, setLoading, setSelectedRowData }: any) {
  useEffect(() => {
    getUserTickets(selectedRowData, setSelectedRowData)
  }, [selectedRowData, setSelectedRowData])

  if (!selectedRowData) {
    return null
  }

  return (
    <div style={{ margin: 10, height: "95vh", overflow: "auto" }}>
      {/* {!close ? null : <Fab color="error" size="small" style={{ float: 'right', fontSize: 42, paddingBottom: 2 }} onClick={close}>
      &times;
    </Fab>} */}
      <div className="header">Details</div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          width: "100%",
          overflow: "auto",
        }}
      >
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          {Object.keys(selectedRowData).map((k, i) => {
            return (
              <>
                <Grid item xs={4} key={i}>
                  <h6>
                    {insertSpaces(k)}
                    {": "}
                  </h6>
                </Grid>
                <Grid
                  item
                  xs={8}
                  key={i}
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    overflow: "auto",
                  }}
                >
                  <p>
                    {" "}
                    {typeof selectedRowData[k] === "object"
                      ? JSON.stringify(selectedRowData[k])
                      : selectedRowData[k] + ""}
                  </p>
                </Grid>
              </>
            )
          })}
        </Grid>

        <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
          {/* <Button fullWidth variant="contained"  onClick={() => sendPasswordReset(selectedRowData.email)}>Send email verification</Button> */}
          <FormControlLabel
            control={
              <Switch
                color="default"
                checked={!selectedRowData.disabled}
                onChange={async (e) => {
                  try {
                    const disabled = !e.target.checked
                    const uid = selectedRowData.uid
                    await updateUser({ uid, disabled })
                    await updateData({
                      path: `${PATH}/${uid}`,
                      setLoading,
                      data: {
                        disabled,
                      },
                    } as any)

                    setSelectedRowData((data: any) => {
                      return {
                        ...data,
                        disabled,
                      }
                    })
                    alert("User disabled successfully")
                    console.log("e.target.checked---->", disabled)
                  } catch (error) {
                    alert("User disabled error:  " + JSON.stringify(error))
                  }
                }}
              />
            }
            label="Disabled"
          />

          <Button
            fullWidth
            variant="contained"
            onClick={() => {
              updateUser({ uid: selectedRowData.uid, emailVerified: true }).then((result) => {
                alert("User updated successfully")
              })
            }}
          >
            Mark email as verified
          </Button>

          {selectedRowData.email ? (
            <Button
              fullWidth
              variant="contained"
              onClick={() => sendPasswordReset(selectedRowData.email)}
            >
              Send password reset
            </Button>
          ) : null}
          <RenderNotificationForm user={selectedRowData} />
        </div>
      </div>
    </div>
  )
}

function RenderNotificationForm({ user } : any) {
  const [formData, setFormData] = useState<any>()

  return !user ? null : (
    <Form
      formData={formData}
      schema={schema as any}
      uiSchema={uiSchema}
      validator={validator}
      onChange={(form) => {
        setFormData(form.formData)
      }}
      onSubmit={async (form) => {
        const data = form.formData
        const canSend =
          (typeof user.pushNotifications === "undefined" || user.pushNotifications === true) &&
          user.pushNotificationToken
        if (canSend) {
          LoadingService.showLoading("Sending Notification")
          await sendNotifications({
            notification: {
              title: data.notificationTitle,
              body: data.notificationText,
            },
            tokens: [user.pushNotificationToken],
          })
          setFormData({})
          LoadingService.hideLoading()
          alert("Notification sent.")
        } else {
          alert(
            "This user does not have a notification token or has notifications disabled from configurations.",
          )
        }

        console.log("form.formData--formData-->", form)
      }}
      onError={(error) => console.log("ERROR!!!", error)}
    />
  )
}

const uiSchema = {
  notificationText: {
    "ui:widget": "textarea",
  },
}

const schema = {
  title: "Send Notification:",
  type: "object",
  required: ["notificationTitle", "notificationText"],
  properties: {
    notificationTitle: { type: "string", title: "Notification Title" },
    notificationText: { type: "string", title: "Notification Text" },
  },
}

import { IconButton, Tooltip } from "@mui/material"

import BlockIcon from "@material-ui/icons/Block"
import { CheckSharp } from "@mui/icons-material"
import DeleteIcon from "@material-ui/icons/Delete"
import EditIcon from "@material-ui/icons/Edit"
import FileCopyIcon from "@material-ui/icons/FileCopy"
import React from "react"
import { Stack } from "@fluentui/react"

const Toolbar: React.FC<{
  onDelete?: () => void
  onDuplicate?: () => void
  onEdit?: () => void
  onDisable?: () => void
  disabled?: boolean
}> = ({ onDelete, onDuplicate, onEdit, onDisable, disabled }) => {
  return (
    <Stack
      horizontal
      horizontalAlign="center"
      verticalAlign="center"
      className="bg-gray-200 rounded-md shadow-md"
      tokens={{ childrenGap: 10 }}
    >
      <EditButton onEdit={onEdit} />
      <DuplicateButton onDuplicate={onDuplicate} />
      <DeleteButton onDelete={onDelete} />
      <DisableButton disabled={disabled} onDisable={onDisable} />
    </Stack>
  )
}

export default Toolbar

export function DuplicateButton({
  onDuplicate,
}: {
  onDuplicate?: (() => void) | undefined
}): React.ReactNode | Iterable<React.ReactNode> {
  return !onDuplicate ? null : (
    <Tooltip title="Duplicate">
      <IconButton aria-label="duplicate" onClick={onDuplicate} color="success">
        <FileCopyIcon />
      </IconButton>
    </Tooltip>
  )
}

export function DeleteButton({
  onDelete,
}: {
  onDelete?: (() => void) | undefined
}): React.ReactNode | Iterable<React.ReactNode> {
  return !onDelete ? null : (
    <Tooltip title="Delete">
      <IconButton aria-label="delete" onClick={onDelete} color="warning">
        <DeleteIcon />
      </IconButton>
    </Tooltip>
  )
}

export function DisableButton({
  onDisable,
  disabled,
}: {
  disabled?: boolean
  onDisable?: (() => void) | undefined
}): React.ReactNode | Iterable<React.ReactNode> {
  return !onDisable ? null : (
    <Tooltip title={disabled ? "Enable Raffle" : "Disable Raffle"}>
      <IconButton aria-label="disable" onClick={onDisable} color="primary">
        {disabled ? <CheckSharp /> : <BlockIcon />}
      </IconButton>
    </Tooltip>
  )
}

export function EditButton({
  onEdit,
}: {
  onEdit?: (() => void) | undefined
}): React.ReactNode | Iterable<React.ReactNode> {
  return !onEdit ? null : (
    <Tooltip title="Edit">
      <IconButton aria-label="edit" onClick={onEdit} color="success">
        <EditIcon />
      </IconButton>
    </Tooltip>
  )
}

import { Add, Delete, Edit } from "@material-ui/icons"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material"
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers"
import React, { useEffect, useState } from "react"
import { addDoc, collection, deleteDoc, doc, getDocs, setDoc, updateDoc } from "firebase/firestore"

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { Form } from "@rjsf/material-ui"
import { MultilineWidget } from "./components/CustomWidgets"
import { db } from "./firebase"
import parse from "html-react-parser"
import { v4 as uuidv4 } from "uuid"
import validator from "@rjsf/validator-ajv8"

const schema: any = {
  title: "Notification",
  type: "object",
  required: ["title", "details", "enabled"],
  properties: {
    title: { type: "string", title: "Title", format: "html" },
    details: { type: "string", title: "Details", format: "html" },
    enabled: { type: "boolean", title: "Enabled" },
    activationDate: { type: "string", format: "date-time", title: "Activation Date" },
    deactivationDate: { type: "string", format: "date-time", title: "Deactivation Date" },
    icon: {
      type: "string",
      title: "Icon",
      enum: ["info", "warning", "error", "success", "notification"],
      enumNames: ["Info", "Warning", "Error", "Success", "Notification"],
    },
  },
}

const uiSchema: any = {
  title: { "ui:widget": MultilineWidget },
  details: { "ui:widget": MultilineWidget },
}

// Icon mapping
const iconMap: any = {
  info: <Add />,
  warning: <Delete />,
  error: <Edit />,
  success: <Add />,
  notification: <Edit />,
}

export const BusinessNotificationsComponent = ({ businessId }: any) => {
  const [notifications, setNotifications] = useState<any[]>([])
  const [openDialog, setOpenDialog] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [selectedNotification, setSelectedNotification] = useState<any>(null)

  // Fetch notifications
  useEffect(() => {
    const fetchNotifications = async () => {
      const querySnapshot = await getDocs(collection(db, "businesses", businessId, "notifications"))
      const notificationData: any[] = []
      querySnapshot.forEach((doc) => {
        const data = doc.data()
        notificationData.push({ id: doc.id, ...data })
        // const now = new Date()
        // if (
        //   (data.activationDate ? new Date(data.activationDate) <= now : true) &&
        //   (data.deactivationDate ? new Date(data.deactivationDate) >= now : true) &&
        //   data.enabled
        // ) {
        //   notificationData.push({ id: doc.id, ...data })
        // }
      })
      setNotifications(notificationData)
    }

    fetchNotifications()
  }, [])

  // Add new notification
  const handleAdd = async (data: any) => {
    try {
      const newDoc = doc(collection(db, "businesses", businessId, "notifications"))

      const newNotification = { ...data, id: newDoc.id }
      await setDoc(newDoc, newNotification)

      setNotifications([...notifications, newNotification])
      setOpenDialog(false)
    } catch (error) {
      console.error("Error adding notification:", error)
    }
  }

  // Edit notification
  const handleEdit = async (data: any) => {
    try {
      await updateDoc(
        doc(db, "businesses", businessId, "notifications", selectedNotification.id),
        data,
      )
      setNotifications(
        notifications.map((n) =>
          n.id === selectedNotification.id ? { ...selectedNotification, ...data } : n,
        ),
      )
      setOpenDialog(false)
      setIsEditing(false)
      setSelectedNotification(null)
    } catch (error) {
      console.error("Error editing notification:", error)
    }
  }

  // Delete notification
  const handleDelete = async (id: any) => {
    try {
      await deleteDoc(doc(db, "businesses", businessId, "notifications", id))
      setNotifications(notifications.filter((n) => n.id !== id))
    } catch (error) {
      console.error("Error deleting notification:", error)
    }
  }

  // Toggle notification status
  const toggleEnabled = async (id: string, currentStatus: any) => {
    try {
      await updateDoc(doc(db, "businesses", businessId, "notifications", id), {
        enabled: !currentStatus,
      })
      setNotifications(
        notifications.map((n) => (n.id === id ? { ...n, enabled: !currentStatus } : n)),
      )
    } catch (error) {
      console.error("Error toggling notification status:", error)
    }
  }

  // Open dialog
  const openAddDialog = () => {
    setSelectedNotification(null)
    setIsEditing(false)
    setOpenDialog(true)
  }

  // Open edit dialog
  const openEditDialog = (notification: any) => {
    setSelectedNotification(notification)
    setIsEditing(true)
    setOpenDialog(true)
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className="p-4">
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-2xl font-bold">Admin Notifications</h1>
          <Button variant="contained" color="primary" startIcon={<Add />} onClick={openAddDialog}>
            Add Notification
          </Button>
        </div>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Icon</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Details</TableCell>
                <TableCell>Enabled</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {notifications.map((notification) => (
                <TableRow key={notification.id}>
                  <TableCell>{iconMap[notification.icon]}</TableCell>
                  <TableCell>
                    <Typography dangerouslySetInnerHTML={{ __html: notification.title }} />
                  </TableCell>
                  <TableCell>
                    <Typography dangerouslySetInnerHTML={{ __html: notification.details }} />
                  </TableCell>
                  <TableCell>
                    <Switch
                      checked={notification.enabled}
                      onChange={() => toggleEnabled(notification.id, notification.enabled)}
                      color="primary"
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton color="primary" onClick={() => openEditDialog(notification)}>
                      <Edit />
                    </IconButton>
                    <IconButton color="secondary" onClick={() => handleDelete(notification.id)}>
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Add/Edit Notification Dialog */}
        <Dialog open={openDialog} onClose={() => setOpenDialog(false)} fullWidth maxWidth="sm">
          <DialogTitle>{isEditing ? "Edit Notification" : "Add Notification"}</DialogTitle>
          <DialogContent>
            <Form
              validator={validator}
              uiSchema={uiSchema}
              schema={schema}
              formData={selectedNotification || {}}
              onSubmit={({ formData }) => (isEditing ? handleEdit(formData) : handleAdd(formData))}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          </DialogActions>
        </Dialog>
      </div>
    </LocalizationProvider>
  )
}

// import { Add, Delete, Edit } from "@material-ui/icons"
// import { Avatar, FormLabel, Tooltip } from "@mui/material"
// import {
//   Button,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   IconButton,
//   Paper,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
// } from "@mui/material"
// import { DataProvider, ListComponent } from "./GenericListComponent"
// import { ImageWidget, SelectWidget } from "./components/CustomWidgets"
// import { addDoc, collection, deleteDoc, doc, getDocs, updateDoc } from "firebase/firestore"
// import { useEffect, useState } from "react"

// import { BusinessAdsConfig } from "./BusinessAdsConfig"
// import { Form } from "@rjsf/material-ui"
// import { JSONSchema7 } from "json-schema"
// import { db } from "./firebase"
// import { v4 as uuidv4 } from "uuid"

// // const data = {
// //   getListLocation(id) {
// //     return ["businesses", id, "ads"]
// //   },
// //   EDIT_TITLE: "Edit Ad",
// //   ADD_TITLE: "Add Ad",
// //   LOADING_MESSAGE: "Loading ads...",
// //   TITLE: "Business Ads",
// //   IMAGES_BUCKET: "adsImages",

// //   columnsProperties: [
// //     { hidden: true, name: "id", label: "Id" },
// //     { required: false, name: "disabled", label: "Disabled", type: "boolean" },

// //     { required: true, name: "expirationDate", label: "Expiration Date" },
// //     { required: true, name: "name", label: "Name" },
// //     { required: true, name: "order", label: "Order" },
// //     {
// //       hidden: false,
// //       type: "array",
// //       required: false,
// //       name: "images",
// //       label: "Images",
// //       options: {
// //         customBodyRender: (value) => {
// //           const arr = Array.isArray(value) ? value : [value]
// //           return (
// //             <div className="flex flex-row gap-1 flex-wrap">
// //               {arr.map((x, i) => {
// //                 return (
// //                   <a key={i} href={x?.link} target="_blank" rel="noreferrer">
// //                     <FormLabel>{x?.title}</FormLabel>
// //                     <Tooltip title={x?.title}>
// //                       <Avatar variant="square" src={x?.image} alt={`${x?.title}-Image`} />
// //                     </Tooltip>
// //                   </a>
// //                 )
// //               })}
// //             </div>
// //           )
// //         },
// //       },
// //       items: {
// //         type: "object",
// //         properties: {
// //           title: {
// //             type: "string",
// //             title: "Title",
// //           },
// //           link: {
// //             type: "string",
// //             title: "Link",
// //           },
// //           image: {
// //             type: "string",
// //             title: "Image",
// //           },
// //           linkTarget: {
// //             type: "string",
// //             title: "Link",
// //             enum: ["_self", "_blank", "_top"],
// //           },
// //           viewLinkOnPopupIframe: {
// //             type: "boolean",
// //             title: "View Link On Popup Iframe",
// //           },
// //         },
// //       },
// //     },
// //     { required: true, name: "description", label: "Description" },
// //     { required: true, name: "language", label: "Language" },
// //     { required: true, name: "country", label: "Country" },
// //     { required: false, name: "state", label: "State" },
// //     { required: false, name: "city", label: "City" },
// //     { required: false, name: "street", label: "Street" },
// //     { required: false, name: "county", label: "County" },
// //     { required: false, name: "zipCode", label: "Zip Code" },
// //     { required: false, name: "generateSocialMediaAds", label: "Generate Social Media Ads" },
// //     { required: false, name: "socialMediaAdsInfo", label: "Social Media Ads Info" },
// //   ],

// //   uiSchema: {
// //     images: {
// //       items: {
// //         image: {
// //           "ui:widget": ImageWidget,
// //         },
// //       },
// //     },
// //     expirationDate: {
// //       "ui:widget": "datetime",
// //     },

// //     description: {
// //       "ui:widget": "textarea",
// //     },
// //   },

// //   async imageProcessor(fromData, handleUpload, id) {
// //     if (fromData.images?.length > 0) {
// //       const imagesURLs = await Promise.all(
// //         fromData.images
// //           .map(({ image, ...rest }) => {
// //             return handleUpload(id, this.IMAGES_BUCKET, image, rest)
// //           })
// //           .filter((x) => !!x),
// //       )
// //       fromData.images = imagesURLs
// //     }

// //     return fromData
// //   },
// //   widgets: { SelectWidget },
// // }

// // export const BusinessNotificationsComponent = ({ businessId }) => {
// //   return (
// //     <div>
// //       <BusinessAdsConfig businessId={businessId} />
// //       <DataProvider {...data} mainId={businessId}>
// //         <ListComponent />
// //       </DataProvider>
// //     </div>
// //   )
// // }

// const schema = {
//   title: "Notification",
//   type: "object",
//   required: ["title", "details", "enabled"],
//   properties: {
//     title: { type: "string", title: "Title" },
//     details: { type: "string", title: "Details" },
//     enabled: { type: "boolean", title: "Enabled" },
//     activationDate: { type: "string", format: "date-time", title: "Activation Date" },
//     deactivationDate: { type: "string", format: "date-time", title: "Deactivation Date" },
//   },
// };

// export const BusinessNotificationsComponent = () => {
//   const [notifications, setNotifications] = useState([])
//   const [openDialog, setOpenDialog] = useState(false)
//   const [isEditing, setIsEditing] = useState(false)
//   const [selectedNotification, setSelectedNotification] = useState(null)

//   // Fetch notifications
//   useEffect(() => {
//     const fetchNotifications = async () => {
//       const querySnapshot = await getDocs(collection(db, "businesses", "id", "notifications"))
//       const notificationData = []
//       querySnapshot.forEach((doc) => {
//         notificationData.push({ id: doc.id, ...doc.data() })
//       })
//       setNotifications(notificationData)
//     }

//     fetchNotifications()
//   }, [])

//   // Add new notification
//   const handleAdd = async (data) => {
//     try {
//       const newNotification = { ...data, id: uuidv4() }
//       await addDoc(collection(db, "businesses", "id", "notifications"), newNotification)
//       setNotifications([...notifications, newNotification])
//       setOpenDialog(false)
//     } catch (error) {
//       console.error("Error adding notification:", error)
//     }
//   }

//   // Edit notification
//   const handleEdit = async (data) => {
//     try {
//       await updateDoc(doc(db, "businesses", "id", "notifications", selectedNotification.id), data)
//       setNotifications(
//         notifications.map((n) =>
//           n.id === selectedNotification.id ? { ...selectedNotification, ...data } : n,
//         ),
//       )
//       setOpenDialog(false)
//       setIsEditing(false)
//       setSelectedNotification(null)
//     } catch (error) {
//       console.error("Error editing notification:", error)
//     }
//   }

//   // Delete notification
//   const handleDelete = async (id) => {
//     try {
//       await deleteDoc(doc(db, "businesses", "id", "notifications", id))
//       setNotifications(notifications.filter((n) => n.id !== id))
//     } catch (error) {
//       console.error("Error deleting notification:", error)
//     }
//   }

//   // Open dialog
//   const openAddDialog = () => {
//     setSelectedNotification(null)
//     setIsEditing(false)
//     setOpenDialog(true)
//   }

//   // Open edit dialog
//   const openEditDialog = (notification) => {
//     setSelectedNotification(notification)
//     setIsEditing(true)
//     setOpenDialog(true)
//   }

//   return (
//     <div className="p-4">
//       <div className="flex justify-between items-center mb-4">
//         <h1 className="text-2xl font-bold">Admin Notifications</h1>
//         <Button variant="contained" color="primary" startIcon={<Add />} onClick={openAddDialog}>
//           Add Notification
//         </Button>
//       </div>

//       <TableContainer component={Paper}>
//         <Table>
//           <TableHead>
//             <TableRow>
//               <TableCell>Title</TableCell>
//               <TableCell>Details</TableCell>
//               <TableCell>Actions</TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {notifications.map((notification) => (
//               <TableRow key={notification.id}>
//                 <TableCell>{notification.title}</TableCell>
//                 <TableCell>{notification.details}</TableCell>
//                 <TableCell>
//                   <IconButton color="primary" onClick={() => openEditDialog(notification)}>
//                     <Edit />
//                   </IconButton>
//                   <IconButton color="secondary" onClick={() => handleDelete(notification.id)}>
//                     <Delete />
//                   </IconButton>
//                 </TableCell>
//               </TableRow>
//             ))}
//           </TableBody>
//         </Table>
//       </TableContainer>

//       {/* Add/Edit Notification Dialog */}
//       <Dialog open={openDialog} onClose={() => setOpenDialog(false)} fullWidth maxWidth="sm">
//         <DialogTitle>{isEditing ? "Edit Notification" : "Add Notification"}</DialogTitle>
//         <DialogContent>
//           <Form
//             schema={schema}
//             formData={selectedNotification || {}}
//             onSubmit={({ formData }) => (isEditing ? handleEdit(formData) : handleAdd(formData))}
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
//         </DialogActions>
//       </Dialog>
//     </div>
//   )
// }

import get from "./get"
import sortBy from "lodash.sortby"

export function getData({ setData, path, getMap, isCollection, setLoading, filterFunction }: any) {
  if (!path) {
    return console.error("PATH is required", {
      setData,
      path,
      getMap,
      isCollection,
      setLoading,
    })
  }
  get({
    isCollection,
    path,
    setData,
    setLoading,
    map: !getMap
      ? null
      : ({ item, path }: any) => {
          const arr = getMap({ item, path })
          return sortBy(arr, (item: any) => item.order)
        },
    filterFunction,
  } as any)
}

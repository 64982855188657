import reduce from "lodash.reduce"

const getMap = ({ item, path }: any) => {
  if (!item) {
    return []
  }
  const arr = reduce(
    item,
    (prev: any[], cur: any, index: string) => {
      console.log(prev, cur, index)
      if (!index.match(/^id/i)) {
        prev.push({ ...cur, key: index })
      }
      return prev
    },
    [],
  )
  return arr
}
export default getMap

import { deleteField, doc, updateDoc, writeBatch } from "firebase/firestore"

import { db } from "../../firebase"

export const options = ({ path, data, onSuccess }: any) => {
  return {
    filterType: "checkbox",
    onRowsDelete: async (rowsDeleted: { data: any[] }, rowData: any, newTableData: any) => {
      console.log({ rowsDeleted, rowData, newTableData })

      const cityRef = doc(db, typeof path === "string" ? path : path.split("/"))

      // Get a new write batch
      // const batch = writeBatch(db);

      rowsDeleted.data.forEach(async (element: { dataIndex: string | number }) => {
        const row = data[element.dataIndex]
        const key = row ? row.key : ""
        const laRef = doc(db, typeof path === "string" ? path : path.split("/"))
        await updateDoc(cityRef, {
          [key]: deleteField(),
        })
      })

      onSuccess && onSuccess(data)

      // await updateDoc(cityRef, {
      //   [key]: deleteField(),
      // });

      // Commit the batch
      // await batch.commit();

      return false
    },
  }
}

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { LoadingService, PopupService } from "react-ui-components/dist/components"
import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
} from "firebase/firestore"
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { db, functions, storage } from "./firebase"
import { generateUUID, getFileExtension, getSchema, isBase64Image } from "./utils"
import { getDownloadURL, ref, uploadBytes } from "firebase/storage"

import { Add as AddIcon } from "@mui/icons-material"
import { Button } from "@mui/material"
import { DateTimeField } from "@mui/x-date-pickers"
import Form from "@rjsf/mui"
import LoadingScreen from "react-loading-screen"
import MUIDataTable from "mui-datatables"
import React from "react"
import dayjs from "dayjs"
import { httpsCallable } from "firebase/functions"
import { useNavigate } from "react-router-dom"
import validator from "@rjsf/validator-ajv8"

export const deleteCollectionAndImages = httpsCallable(functions, "deleteCollectionAndImages")

const deleteAllContent = async (
  documentIds: string,
  mainId: string,
  Service: {
    getListLocation: (id: string) => string[]
    TITLE?: string
    LOADING_MESSAGE?: string
    IMAGES_BUCKET: string
    EDIT_TITLE?: string
    ADD_TITLE?: string
    columnsProperties?: string
    uiSchema?: string
    imageProcessor?: string
    onRowClick?: () => void
  },
) => {
  try {
    LoadingService.setLoading(true)

    await deleteCollectionAndImages({
      bucketPath: Service.IMAGES_BUCKET,
      collectionPath: Service.getListLocation(mainId).join("/"),
      documentIds,
    })
    console.log("Collection and images deleted successfully.")
  } catch (error: any) {
    const msg = "Error deleting collection and images"
    LoadingService.setLoading(true, msg, true)
    console.error(msg, error)
    PopupService.pushPopup(`${msg}\n${error.message}`)
  } finally {
    LoadingService.setLoading(false)
  }
}

const GenericListProps: any = null
const Context = createContext(GenericListProps)

export const useData = () => {
  return useContext(Context)
}

export const DataProvider = ({
  children,
  getListLocation,
  TITLE,
  LOADING_MESSAGE,
  IMAGES_BUCKET,
  columnsProperties,
  uiSchema,
  imageProcessor,
  EDIT_TITLE,
  ADD_TITLE,
  onRowClick,
  mainId,
  format,
  ...rest
}: any) => {
  const [list, setList] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const [columns, setColumns] = useState<any>([])
  const [options, setOptions] = useState<any>({})

  const navigate = useNavigate()

  const Service = useMemo(
    () => ({
      getListLocation,
      TITLE,
      LOADING_MESSAGE,
      IMAGES_BUCKET,
      EDIT_TITLE,
      ADD_TITLE,
      columnsProperties,
      uiSchema,
      imageProcessor,
      onRowClick,
      ...rest,
    }),
    [
      ADD_TITLE,
      EDIT_TITLE,
      IMAGES_BUCKET,
      LOADING_MESSAGE,
      TITLE,
      columnsProperties,
      getListLocation,
      imageProcessor,
      uiSchema,
      onRowClick,
    ],
  )

  const reload = useCallback(async () => {
    try {
      setLoading(true)
      const collectionInfo = Service.getListLocation(mainId)
      let list = []
      if (Array.isArray(collectionInfo)) {
        const querySnapshot = await getDocs(collection(db, collectionInfo.join("/")))
        list = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
      } else {
        /*
         subCollectionDoc: "ads",
      path: ["businesses", id],
      startsWith: "config-",
      propHoldingSubCollection: 'ads-config'
        */
        const docRef = doc(db, collectionInfo.path)

        const docSnap = await getDoc(docRef)
        if (docSnap.exists()) {
          const rawData: any = { ...docSnap.data(), id: docSnap.id }

          list = rawData[collectionInfo.propHoldingSubCollection]
        }
      }

      setList(list)
    } catch (error: any) {
      console.log(`Get ${Service.TITLE} list error`, error)
      PopupService.pushPopup(error.message)
    } finally {
      setLoading(false)
    }
  }, [Service, mainId])
  // const [reload] = useState(reloadFunc)

  useEffect(() => {
    reload && reload()
  }, [reload])

  useEffect(() => {
    setColumns([
      ...Service.columnsProperties.map((x: { uiType: string }) => {
        if (x.uiType === "datetime2") {
          return {
            ...x,
            options: {
              customBodyRender: (
                value: string | number | Date | dayjs.Dayjs | null | undefined,
                tableMeta: any,
                updateValue: any,
              ) => {
                return (
                  <DateTimeField
                    readOnly
                    variant="standard"
                    fullWidth
                    style={{ width: 220 }}
                    // label="Format with meridiem"
                    defaultValue={dayjs(value)}
                    format="LLL"
                  />
                )
                // return <DateTimeWidget  value={value} />
              },
            },
          }
        }
        return x
      }),
      {
        name: "edit",
        label: "Edit",
        options: {
          customBodyRender: (
            value: any,
            tableMeta: { rowIndex: string | number },
            updateValue: any,
          ) => {
            return (
              <Button
                variant="contained"
                color="primary"
                onClick={(e) => {
                  e.stopPropagation()
                  PopupService.setPopup(
                    <AddEditComponent
                      Service={Service}
                      mainId={mainId}
                      format={format}
                      data={list[tableMeta.rowIndex]}
                      onAdded={() => {
                        reload && reload()
                        PopupService.setPopup(null)
                      }}
                    />,
                  )
                }}
              >
                Edit
              </Button>
            )
          },
        },
      },
    ])

    setOptions({
      // responsive: "simple",
      onRowsDelete: (e: { data: any[] }, m: any) => {
        PopupService.setConfirm(
          "Are you sure you want to delete the selected items and all it's data",
          async () => {
            const ids: any = e.data.map(
              (x: { dataIndex: string | number }) => list?.[x.dataIndex]?.id,
            )
            await deleteAllContent(ids, mainId, Service)
            reload && reload()
          },
        )
        // const result = confirm(
        //   "Are you sure you want to delete the selected items and all it's data",
        // )
        // if (result) {
        //   ;(async function () {
        //     const ids: any = e.data.map(
        //       (x: { dataIndex: string | number }) => list?.[x.dataIndex]?.id,
        //     )
        //     await deleteAllContent(ids, mainId, Service)
        //     reload && reload()
        //   })()
        // }
        return false
      },
      onRowClick: Service.onRowClick?.(list, navigate),
    })
  }, [Service, mainId, list, reload, navigate])

  Service.setData = setList

  useEffect(() => {
    Service.getData = () => {
      return list
    }
  }, [Service, list])

  return (
    <Context.Provider value={{ list, loading, columns, options, reload, mainId, Service, format }}>
      {children}
    </Context.Provider>
  )
}

export const ListComponent = ({ onSuccess, format, defaultAddData }: any) => {
  const { list, loading, columns, options, reload, mainId, Service } = useData()
  return (
    <LoadingScreen
      loading={loading}
      bgColor="#f1f1f1"
      spinnerColor="#9ee5f8"
      textColor="#676767"
      logoSrc="/logo512.png"
      text={Service.LOADING_MESSAGE}
    >
      <div className="m-3">
        <Button
          variant="contained"
          color="primary"
          onClick={(e) => {
            e.stopPropagation()
            PopupService.setPopup(
              <AddEditComponent
                Service={Service}
                mainId={mainId}
                format={format}
                data={defaultAddData}
                onAdded={() => {
                  reload && reload()
                  onSuccess && onSuccess()
                }}
              />,
            )
          }}
        >
          <AddIcon />
        </Button>
      </div>
      {loading ? null : (
        <MUIDataTable
          responsive="simple"
          title={Service.TITLE}
          data={list}
          columns={columns}
          options={options}
        />
      )}
    </LoadingScreen>
  )
}

export const AddEditComponent = ({ close, onAdded, data, mainId, Service, onAdd, format }: any) => {
  const [schema, setSchema] = useState({})

  const [formData, setFormData] = useState(data)

  useEffect(() => {
    const cols = Service.columnsProperties.filter((x: { hidden: any }) => !x.hidden)
    if (!cols?.length) {
      return
    }

    const sm = Service.schema
      ? Service.schema
      : getSchema(data ? Service.EDIT_TITLE : Service.ADD_TITLE, cols)
    setSchema(sm)
  }, [Service.ADD_TITLE, Service.EDIT_TITLE, Service.columnsProperties, data, Service.schema])

  const handleUpload = async (
    id: any,
    fieldName: any,
    image: string,
    imageInfo: any,
    imagePropName: any,
  ) => {
    const isBase64 = isBase64Image(image)
    if (!isBase64) {
      return imageInfo
        ? {
            ...imageInfo,
            image,
          }
        : image
    }

    const uid = generateUUID()
    const storageRef = ref(
      storage,
      `${Service.IMAGES_BUCKET}/${id}/${fieldName}-${uid}-${Date.now()}.${getFileExtension(image)}`,
    )

    const response = await fetch(image)
    const blob = await response.blob()
    await uploadBytes(storageRef, blob)

    const downloadURL = await getDownloadURL(storageRef)
    return imageInfo
      ? {
          ...imageInfo,
          image: downloadURL,
        }
      : downloadURL
  }

  function removeUndefinedFields(obj: any[] | null): any {
    if (obj === null || typeof obj !== "object") {
      return obj // Return non-objects as is (e.g., numbers, strings, etc.)
    }

    if (Array.isArray(obj)) {
      // Handle arrays by recursively cleaning each element
      const cleanedArray = obj
        .map((item) => removeUndefinedFields(item))
        .filter((item) => item !== undefined)
      return cleanedArray.length > 0 ? cleanedArray : undefined
    }

    const cleanedObj: any = {}
    Object.keys(obj).forEach((key) => {
      const value = obj[key]

      // Recursively clean nested objects
      const cleanedValue = removeUndefinedFields(value)

      if (cleanedValue !== undefined) {
        cleanedObj[key] = cleanedValue
      }
    })

    // If the cleaned object has no properties, return undefined to remove it
    return Object.keys(cleanedObj).length > 0 ? cleanedObj : undefined
  }

  const handleSubmit = async (result: { formData: any }) => {
    LoadingService.setLoading(true)
    try {
      // console.log("onSubmit", result)
      let formData = removeUndefinedFields(result.formData)

      if (format) {
        const dt = format(formData)
        if (dt) {
          formData = dt
        }
      }

      if (onAdd) {
        return onAdd(formData)
      }

      let id = data?.id

      const collectionInfo = Service.getListLocation(mainId)

      if (Array.isArray(collectionInfo)) {
        if (!data?.id) {
          const docRef = await addDoc(collection(db, collectionInfo.join("/")), formData)

          id = docRef.id
        }

        formData = await Service.imageProcessor(formData, handleUpload, id, mainId)

        await updateDoc(doc(db, collectionInfo.join("/"), id), { id, ...formData })
      }

      if (typeof close === "function") {
        close()
      }

      onAdded && onAdded()
    } catch (error: any) {
      PopupService.pushPopup(error.message)
    } finally {
      LoadingService.setLoading(false)
    }
  }

  return (
    <Form
      formData={formData}
      templates={{
        ObjectFieldTemplate: Service.ObjectFieldTemplate,
      }}
      schema={schema}
      fields={Service.fields}
      uiSchema={Service.uiSchema}
      validator={validator}
      onChange={(data) => setFormData(data.formData)}
      onSubmit={handleSubmit as any}
      widgets={Service.widgets}
    />
  )
}

export async function addSubcollection(
  docPath: string,
  subcollectionPropertyName: string,
  subcollectionName: any,
  subcollectionDocId: string,
  data: any,
) {
  const docRef = doc(db, docPath)

  // Add your subcollection creation logic here
  await addDocumentToSubcollection(docPath, subcollectionName, subcollectionDocId, data)

  // Update the parent document with the subcollection name
  await updateDoc(docRef, {
    [subcollectionPropertyName]: arrayUnion(subcollectionName),
  })
}

export async function removeSubcollectionProperty(
  docPath: string,
  subcollectionPropertyName: string,
  subcollectionName: unknown,
) {
  const docRef = doc(db, docPath)

  // Update the parent document with the subcollection name
  await updateDoc(docRef, {
    [subcollectionPropertyName]: arrayRemove(subcollectionName),
  })
}

async function addDocumentToSubcollection(
  docPath: string,
  subcollectionName: string,
  subcollectionDocId: string | undefined,
  data: any,
) {
  const subcollectionRef = collection(doc(db, docPath), subcollectionName)
  await setDoc(doc(subcollectionRef, subcollectionDocId), data)
}

export async function getSubcollectionData(
  docPath: string,
  subcollectionName: string,
  subcollectionDocId: string | undefined,
) {
  const subcollectionRef = collection(doc(db, docPath), subcollectionName)
  const docRef = await getDoc(doc(subcollectionRef, subcollectionDocId))
  const data = docRef.data()
  return data
}

import { SetStateAction, useState } from "react"

import { Logo } from "./Logo"
import React from "react"

const Home = () => {
  const [submitted, setSubmitted] = useState(false)
  const [data, setData] = useState(null)

  return (
    <div>
      <Logo width={"100%"} />
      <h6>
        WinFacil allows you to participate in various types of giveaways with our easy ticketing
        system. Simply click the “Interested” button and contact your local promoter and get your
        ticket. That’s it! At WinFacil, you can also accumulate Easy Coins, our virtual currency
        that allows you to trade for raffles. You can also transfer easy coins to friends and
        family. Enjoy all the content that WinFácil has to offer, such as exclusive offers and
        specials from our fantastic sponsors. WinFácil, the App of great surprises!
      </h6>
    </div>
  )
}

export default Home

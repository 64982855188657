/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { addDoc, collection } from "firebase/firestore"

import { db } from "../../../firebase"
import reduce from "lodash.reduce"

const add = async ({ key, data, path, setLoading }: any): Promise<void> => {
  try {
    setLoading && setLoading(true)
    data = reduce(
      data,
      (result: { [x: string]: any }, value: any, key: string | number) => {
        result[key] = typeof result[key] === "undefined" ? "" : result[key]
        return result
      },
      data,
    )

    const docRef = await addDoc(
      collection(db, typeof path === "string" ? path : path.split("/")),
      key
        ? {
            [key]: data,
          }
        : data,
    )
    console.log("Document written with ID: ", docRef.id)
  } catch (e) {
    console.error("Error adding document: ", e)
  } finally {
    setLoading && setLoading(false)
  }
}

export default add

import * as htmlToImage from "html-to-image"

import { DateTime, Settings } from "luxon"
import React, { useEffect, useRef, useState } from "react"
import { auth, db } from "../firebase"
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore"

// import Form from '@rjsf/core';
import Form from "@rjsf/material-ui"
import { colors } from "../utils/colors"
// import email_button from "./json/email_button_data.json"
import { jsPDF } from "jspdf"
// import password_button from "./json/password_button_data.json"
// import { renderToStaticMarkup } from "react-dom/server"
// import {RJSFSchema} from '@rjsf/utils';
// import { signInWithEmailAndPassword } from "firebase/auth"
// import submit_button from "./json/submit_button_data.json"
import { useNavigate } from "react-router-dom"
import validator from "@rjsf/validator-ajv8"

const Tickets = ({}) => {
  // const formRef = useRef();

  const [generatedTickets, setGeneratedTickets] = useState<any>([])
  const [generatedRaffles, setGeneratedRaffles] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const [loading2, setLoading2] = useState(false)
  const [selectedRaffle, setSelectedRaffle] = useState<any>(null)
  const [formData, setFormData] = useState<any>()
  const [countPerPage, setCountPerPage] = useState(0)
  const [pagesCount, setPagesCount] = useState(0)
  const [pageContent, setPageContent] = useState<any>([])
  const [loading3, setLoading3] = useState(false)

  const navigate = useNavigate()

  const uiSchema: any = {}

  const schema = (arr: any[]) => ({
    title: "Tickets",
    type: "object",
    required: ["ticketWidth", "ticketHeight", "raffle", "pageHeight", "pageWidth"],
    properties: {
      textColor: { type: "string", title: "Text Color" },
      backgroundColor: { type: "string", title: "Background Color" },
      ticketWidth: { type: "number", title: "Ticket Width" },
      ticketHeight: { type: "number", title: "Ticket Height" },
      pageWidth: { type: "number", title: "Page Width" },
      pageHeight: { type: "number", title: "Page Height" },
      // pageHMargin: { type: 'number', title: 'Page Horizontal Margin' },
      // pageVMargin: { type: 'number', title: 'Page Vertical Margin' },
      raffle: {
        // type: "array",
        title: "Select Raffle",
        uniqueItems: true,
        enumNames: arr?.map((x: { title: any }) => x.title),
        enum: arr,
        // items: arr?.map(x => ({ title: x.title, type: 'string' }))
      },
    },
  })

  async function loadRaffles() {
    setLoading(true)
    const querySnapshot = await getDocs(
      query(collection(db, "raffles"), where("disabled", "==", false)),
    )

    setGeneratedRaffles([])
    querySnapshot.forEach((raffleDoc) => {
      const raffle = raffleDoc.data()
      setGeneratedRaffles((arr: any[]) =>
        arr.concat({ title: raffle.title, type: "string", id: raffle.id, name: raffle.name }),
      )
    })
    setLoading(false)
  }

  useEffect(() => {
    loadRaffles()
  }, [])

  if (loading) {
    return <h1>Loading...</h1>
  }

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <a
        target="_blank"
        href="https://pixelsconverter.com/us-paper-sizes-to-pixels"
        rel="noreferrer"
      >
        Page Sizes
      </a>
      <i>
        If you don't provide ticket width or height it will use the original designed width and
        height
      </i>
      <Form
        formData={{
          raffle: selectedRaffle,
          ticketWidth: 0,
          ticketHeight: 0,
          pageWidth: 816,
          pageHeight: 1054,
          pageHMargin: 40,
          pageVMargin: 20,
        }}
        schema={schema(generatedRaffles) as any}
        uiSchema={uiSchema}
        validator={validator}
        onChange={console.log}
        onSubmit={async (result) => {
          if (result?.errors?.length) {
            return
          }

          // console.log('result---result.formData.raffle.id--->', result.formData.raffle.id);
          const data = result.formData
          const raffle = data.raffle
          setFormData(data)
          setSelectedRaffle(raffle)

          setLoading2(true)

          try {
            const ticketsRef = await getDocs(collection(db, "raffles", raffle.id, "tickets"))
            setGeneratedTickets([])
            const all: any = []
            setGeneratedTickets([])
            setPageContent(null)

            if (ticketsRef.size <= 0) {
              return
            }

            ticketsRef.forEach((ticketDoc) => {
              const ticket = ticketDoc.data()
              // console.log('ticket---->', ticket);
              all.push(ticket)
            })
            setGeneratedTickets(all)
            const height = data.ticketHeight || all[0].design.height
            const width = data.ticketWidth || all[0].design.width

            const pageHeight = data.pageHeight
            const pageWidth = data.pageWidth
            const perPage = Math.floor(
              Math.floor(pageHeight / height) * Math.floor(pageWidth / width),
            )

            const pages = Math.ceil(all.length / perPage)
            setPagesCount(pages)
            setCountPerPage(perPage)

            const allPages = []
            for (let index = 0; index < pages; index++) {
              const rPage = (
                <div
                  id={`tickets-items-${index}`}
                  style={{
                    // marginTop: data.pageVMargin,
                    // marginBottom: data.pageVMargin,
                    margin: 10,
                    display: "flex",
                    flexWrap: "wrap",
                    flexDirection: "row",
                    width: pageWidth || 816,
                    // height: data?.pageHeight || 1054
                  }}
                >
                  {all
                    .slice(index * (perPage - 1), (index + 1) * perPage)
                    .map((item: any, i: any) => {
                      return (
                        <TicketItem
                          key={`${item.id}-${i}`}
                          item={{
                            ...item,
                            design: {
                              ...item.design,
                              width: data.ticketWidth || item.design.width,
                              height: data.ticketHeight || item.design.height,
                              color: data.backgroundColor || item.design.backgroundColor,
                              textColor: data.textColor || item.design.color,
                            },
                          }}
                        />
                      )
                    })}
                </div>
              )

              allPages.push(rPage)
            }
            setPageContent(allPages)
          } catch (error) {
            console.error("ERROR getting tickets--->", error)
          } finally {
            setLoading2(false)
          }

          // const querySnapshot = await getDocs(collection(db, 'raffles'));

          // querySnapshot.forEach(async (raffleDoc) => {
          //   //
          //   // ticketsRef.forEach(async (ticketDoc) => {
          //   //   const ticket = ticketDoc.data();
          //   //   console.log('ticket---->', ticket);
          //   //   setGeneratedTickets(arr => arr.concat(ticket));
          //   // });
          // });
        }}
        onError={console.error}
      />

      {!loading3 ? null : <h1>Generating pdf...</h1>}
      {loading2 ? (
        <h1>Loading...</h1>
      ) : (
        <div
          style={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <button
            onClick={async () => {
              setLoading3(true)
              try {
                const pdf = new jsPDF({ unit: "px", format: "letter", userUnit: "px" } as any)

                for (let index = 0; index < pageContent.length; index++) {
                  const input = document.getElementById(`tickets-items-${index}`)

                  // htmlToImage.toBlob(input)
                  //   .then(function (blob) {
                  //     window.saveAs(blob, `tickets-items-${index}.png`);
                  //   });
                  // return;
                  const dataUrl = await htmlToImage.toPng(input as any, { quality: 0.95 })
                  const link = document.createElement("a")
                  link.download = "my-image-name.jpeg"
                  // const pdf = new jsPDF();
                  const pdfWidth = pdf.internal.pageSize.getWidth()
                  const imgProps = pdf.getImageProperties(dataUrl)

                  const pdfHeight =
                    (imgProps.height * pdfWidth) / imgProps.width + (formData?.pageVMargin + 20) ||
                    0
                  pdf.addImage(dataUrl, "PNG", 0, 0, pdfWidth, pdfHeight)
                  pdf.addPage()
                }

                pdf.save(`${(selectedRaffle?.name || "raffle").replace(/\s/gi, "_")}.pdf`)
              } catch (error) {
                console.error("There was an error generating the pdf.", error)
              } finally {
                setLoading3(false)
              }
            }}
          >
            Download
          </button>

          {pageContent}
        </div>
      )}
    </div>
  )
}

export default Tickets

export const getLuxonDate = (date: any, isSeconds = false): any => {
  if (!date) {
    return console.log(`Invalid date (${date}).`)
  }
  let date_ = null
  if (isSeconds) {
    date_ = DateTime.fromMillis(date)
    date_ = date_.isValid ? date_ : DateTime.fromSeconds(date)
  } else {
    date_ = DateTime.fromISO(date)
    date_ = date_.isValid ? date_ : DateTime.fromJSDate(date)
  }
  return date_
}

const View = ({ children, style }: any) => (
  <div style={{ flexDirection: "column", ...getStyle(style) }}>{children}</div>
)
const Image = ({ children, uri, resizeMode, style }: any) => {
  return (
    <div
      style={{
        flexDirection: "column",
        backgroundImage: uri,
        ...getStyle(style),
      }}
    >
      {children}
    </div>
  )
} //uri={design?.image} resizeMode="stretch" style={style}
const TouchableOpacity = ({ children, style }: any) => (
  <div style={{ flexDirection: "column", ...getStyle(style) }}>{children}</div>
)

const hide = (elem: React.JSX.Element, item: { [x: string]: any }, prop: string) =>
  item[prop] ? elem : null

const DefaultTicketDesign = {
  width: 180,
  height: 120,
  margin: 5,
  padding: 5,
}

const GetTicketDesign = (
  obj: { margin: any; padding: any; width: any; height: any },
  offset?: { margin: any; padding: any; width: any; height: any } | undefined,
  marginAndPadding?: undefined,
) => {
  const mAndA = marginAndPadding
    ? {}
    : {
        margin: +(obj?.margin || DefaultTicketDesign.margin) + (offset?.margin || 0),
        padding: +(obj?.padding || DefaultTicketDesign.padding) + (offset?.padding || 0),
      }
  return {
    width: +(obj?.width || DefaultTicketDesign.width) + (offset?.width || 0),
    height: +(obj?.height || DefaultTicketDesign.height) + (offset?.height || 0),
    ...mAndA,
  }
}

function getStyle(style: any[]) {
  return Array.isArray(style)
    ? style.reduce((c, n) => ({ ...c, ...n, display: "flex" }), {})
    : style
}

export function TicketItem(props: { item?: any; onPress?: any }) {
  const { item, onPress } = props
  const [design, setDesign] = useState({ ...item?.design, ...GetTicketDesign(item?.design as any) })
  const [textColor, setTextColor] = useState(item?.design?.textColor)

  useEffect(() => {
    console.log("item.design----->", item?.design?.textColor)
    setDesign({ ...item?.design, ...GetTicketDesign(item?.design) })
    setTextColor(item?.design?.textColor)
  }, [item?.design])

  return (
    <div
      style={{
        width: design.width,
        height: design.height,
        color: textColor,
      }}
    >
      <div
        style={{
          backgroundImage: `url(${design.image || require("./ticket-img-no-name.png")})`,
          backgroundRepeat: "no-repeat",
          padding: design.padding,
          // height: '100%',
          // backgroundSize: "contain"
          backgroundSize: "97% 100%",
        }}
      >
        <div
          style={{
            ...$root(design.color),
            flexDirection: "column",
            ...$innerRoot(design),
            overflow: "hidden",
          }}
        >
          <Row
            hide={!item.code}
            textColor={textColor}
            labelTx="Code"
            value={item.code}
            style={$row}
            size="xs"
          />

          {!item.expirationDate ? null : (
            <Row
              textColor={textColor}
              labelTx="Date"
              value={getLuxonDate(+item.expirationDate, true).toFormat("D")}
              style={$row}
            />
          )}
          {!item.raffleType ? (
            <Text text="TICKET" size="xxl" style={$empty(textColor)} />
          ) : (
            <Row
              textColor={textColor}
              numberOfLines={2}
              labelTx="Raffle"
              value={item.raffleType}
              style={$row}
              valueSize="xxs"
            />
          )}

          {hide(
            <View style={$win}>
              <Text tx="win" preset="bold" size="md" style={$text} />
            </View>,
            item,
            "isWinner",
          )}
        </div>
      </div>{" "}
    </div>
  )
}

const $win = {
  display: "flex",
  position: "absolute",
  right: 0,
  bottom: 0,
  backgroundColor: colors.error,
  borderRadius: 10,
  // height: 30,
  padding: 2,
  justifyContent: "flex-start",
}
const $100p = {
  display: "flex",
  width: "100%",
  height: "100%",
}

const $row = {
  display: "flex",
  alignSelf: "flex-start",
}

const $root = (backgroundColor: any) => ({
  display: "flex",
  borderColor: "#000",
  borderWidth: 2,
  borderRadius: 10,
  padding: 2,
  // margin: 13,
  backgroundColor: backgroundColor || colors.palette.neutral100,
  width: "100%",
  // height: 100,
  justifyContent: "flex-start",
  borderStyle: "solid",
})

const $text = {
  display: "flex",
  color: "#36ab53",
  textAlign: "center",
}

function $emptyDesign(design: { margin: any; width: string | number; height: string | number }) {
  return {
    display: "flex",
    margin: design?.margin,
    width: +design?.width,
    height: +design?.height,
  }
}

function $innerRoot(design: { width: string | number; padding: number; height: string | number }) {
  return {
    display: "flex",
    padding: 5,
    width: +design?.width - design?.padding - 10,
    height: +design?.height - design?.padding - 10,
  }
}

function $empty(textColor: any) {
  return {
    display: "flex",
    alignSelf: "center",
    color: textColor,
  }
}

function $main(design: { padding: any }) {
  return {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    padding: design?.padding,
  }
}

function $parent(design: {
  margin: any
  width: string | number
  height: string | number
  color: any
  backgroundColor: any
}) {
  console.log("$parent---design---->", design)
  return {
    margin: design?.margin,
    width: +design?.width,
    height: +design?.height,
    backgroundColor: design?.color || design?.backgroundColor,
    overflow: "hidden",
  }
}

export const Row = ({
  label,
  value,

  labelTx,
  valueTx,

  style,
  children,
  wrap,
  size,
  labelSize,
  valueSize,
  numberOfLines = 1,
  spaceBetween = false,
  onPress = null,
  leftContent = false,
  hide = false,
  valueStyle = null,
  labelStyle = null,
  textColor = null,
}: any): React.JSX.Element | null => {
  if (hide) {
    return null
  }
  const Parent = !onPress
    ? ({ children, style }: any): React.JSX.Element => (
        <div
          style={{
            ...getStyle(style),
            display: "flex",
            flexDirection: "row",
          }}
        >
          {children}
        </div>
      )
    : ({ children, style }: any) => <div style={getStyle(style)}>{children}</div>

  const ValueText = (typeof value !== "undefined" || typeof valueTx !== "undefined") && (
    <Text
      style={{ flexWrap: "wrap", color: textColor, fontWeight: "normal", marginTop: 5 }}
      text={value}
      tx={valueTx}
      size={valueSize || size}
      numberOfLines={numberOfLines}
    ></Text>
  )
  const Label = (
    <p style={{ lineHeight: 1 }}>
      <b>{labelTx || label}</b>
      {": "}
      <span style={{ fontSize: 13 }}>{valueTx || value}</span>
    </p>
    // <Text
    //   text={`${label}: `}
    //   tx={labelTx}
    //   size={labelSize || size}
    //   style={{ color: textColor, fontWeight: 'bold' }}
    // >
    //  {": "} {ValueText}
    // </Text>
  )
  return (
    <Parent style={[styles({ wrap, spaceBetween }), style]} onPress={onPress}>
      <div style={{ display: "flex", flexDirection: "row", height: 18 }}>{Label}</div>
      {leftContent ? null : !!children && children}
    </Parent>
  )
}

const styles = (props: { [x: string]: any; wrap?: any; spaceBetween?: any }) => ({
  display: "flex",
  flexDirection: "row",
  flexWrap: props?.wrap ? "wrap" : "nowrap",
  justifyContent: props?.spaceBetween ? "space-between" : undefined,
})

const $sizeStyles: any = {
  xxl: { fontSize: 36 },
  xl: { fontSize: 24 },
  lg: { fontSize: 20 },
  md: { fontSize: 18 },
  sm: { fontSize: 16 },
  xs: { fontSize: 14 },
  xxs: { fontSize: 12 },
}
const Text = ({ children, tx, text, size, style, color, ...rest }: any) => (
  <p style={{ ...getStyle(style), color, ...$sizeStyles[size], ...styles(rest) }}>
    {tx || text}
    {children}
  </p>
) // text="TICKET" size="xxl" style={$empty(textColor)}

export const schema = ({ title }: any) => {
  return {
    title: title,
    type: "object",
    required: ["name", "phone", "email"],
    properties: {
      disabled: { type: "boolean", title: "Disabled" },
      name: { type: "string", title: "Page name" },
      profileImageUrl: { type: "string", title: "Profile Image Url" },
      phone: { type: "string", title: "Phone" },
      email: { type: "string", title: "Email" },
      address: { type: "string", title: "Address" },
    },
  }
}

import { doc, setDoc, updateDoc } from "firebase/firestore"

import { db } from "../../../firebase"
import reduce from "lodash.reduce"

const update = async ({
  path,
  key,
  data,
  persistKey,
  dataKeyValue,
  setOrUpdate,
}: any): Promise<void> => {
  if (!path) {
    console.error("Path is required to update data.")
  }

  const ref = doc(db, typeof path === "string" ? path : path.split("/"))

  if (typeof data !== "string") {
    data = reduce(
      data,
      (result: { [x: string]: any }, value: any, key: string | number) => {
        result[key] = typeof result[key] === "undefined" ? "" : result[key]
        return result
      },
      data,
    )
  }

  if (key) {
    data = persistKey
      ? { ...data, key }
      : // : dataKeyValue
        // ? data
        {
          [key]: data,
        }
  }
  // if (dataKeyValue) {
  // }
  if (dataKeyValue || key) {
    const mergeFields = dataKeyValue ? `${key ? key + "." : ""}${dataKeyValue || ""}` : key

    return await setDoc(ref, data, {
      mergeFields: [mergeFields],
    })
  }
  if (setOrUpdate) {
    return await setDoc(ref, data)
  }

  return await updateDoc(ref, data)
}

export default update

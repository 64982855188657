// import {Form, FormEdit, FormGrid, Grid} from '@formio/react';
import React, { useEffect } from "react"

import { DataTable } from "../core/CreateEditOptions/DataTable"
import add from "../core/CreateEditOptions/add"
import { getColumns } from "./getColumns"
import { getData } from "../core/CreateEditOptions/getData"
import getMap from "./getMap"
import { options } from "./options"
import { schema } from "./schema"
import update from "../core/CreateEditOptions/update"
import { updateData } from "../core/CreateEditOptions/updateData"
import { useState } from "react"

const PATH = "app-configurations/onboarding/pages"
const BUCKET = "onboarding/assets"
const TITLE = "Onboarding Config"
const ADD_TITLE = "Add new Onboarding Config"
const IMAGE_PROP_NAME = "background"

const Main = () => {
  const [data, setData] = useState<any>()
  const [loading, setLoading] = useState<any>()
  const [columns, setColumns] = useState<any>([])

  useEffect(() => {
    getData({ setData, path: PATH, getMap, isCollection: true })
  }, [])

  useEffect(() => {
    if (data?.length) {
      const resultColumns = getColumns({
        imagePropName: IMAGE_PROP_NAME,
        data,
        setLoading,
        setData,
        path: PATH,
        onEditSuccess: async ({ result, close, id }: any) => {
          await updateData({
            path: `${PATH}/${id}`,
            setLoading,
            data: result.formData,
          } as any)

          getData({
            setData,
            path: PATH,
            getMap,
            isCollection: true,
            setLoading,
          })
          close()
        },
      } as any)
      setColumns(resultColumns)
    }
  }, [data])

  if (!data || !Array.isArray(data)) {
    setData([])
  }

  return (
    <DataTable
      isCollection
      imagePropName={IMAGE_PROP_NAME}
      path={PATH}
      bucket={BUCKET}
      title={TITLE}
      addTitle={ADD_TITLE}
      setLoading={setLoading}
      setData={setData}
      getMap={getMap}
      loading={loading}
      data={data}
      columns={columns}
      options={options}
      schema={schema}
      onSuccess={() => {
        getData({ setData, path: PATH, getMap, isCollection: true, setLoading })
      }}
      onEditSuccess={() => {
        getData({ setData, path: PATH, getMap, isCollection: true, setLoading })
      }}
      onAddSuccess={async ({ result, close }: any) => {
        await add({
          path: PATH,
          setLoading,
          data: result.formData,
        } as any)

        getData({ setData, path: PATH, getMap, isCollection: true, setLoading })
        close()
      }}
    />
  )
}

export default Main

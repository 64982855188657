import { Alert } from "@mui/material"
import React from "react"

export const DisableMessage: React.FC<{
  disabled?: boolean
  message?: string
}> = ({ disabled, message }) => {
  return disabled ? (
    <Alert severity="warning" className="mb-4">
      {message || " This item is currently disabled."}
    </Alert>
  ) : null
}

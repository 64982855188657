// import {Form, FormEdit, FormGrid, Grid} from '@formio/react';
import React, { useEffect, useState } from "react"
import { addDoc, collection } from "firebase/firestore"
import { auth, db, functions } from "../../firebase"
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth"

import { DataTable } from "../core/CreateEditOptions/DataTable"
// import FormGroup from "@mui/material/FormGroup"
// import Popup from "reactjs-popup"
import { PopupService } from "react-ui-components/dist/components"
import { UserDetail } from "./UserDetail"
import add from "../core/CreateEditOptions/add"
import { getColumns } from "./getColumns"
import { getData } from "../core/CreateEditOptions/getData"
import getMap from "./getMap"
import { httpsCallable } from "firebase/functions"
import { options } from "./options"
import { schema } from "./schema"
// import update from "../core/CreateEditOptions/update"
import { updateData } from "../core/CreateEditOptions/updateData"
import { useNavigate } from "react-router-dom"

export const updateUser = httpsCallable(functions, "updateUser")

export const PATH = "users"
const BUCKET = "users/assets"
const TITLE = "Users Config"
const ADD_TITLE = "Add new User"
const IMAGE_PROP_NAME = "profileImageUrl"

const Main = () => {
  const [data, setData] = useState<any>()
  const [loading, setLoading] = useState<any>()
  const [columns, setColumns] = useState<any>([])
  const [selectedRowData, setSelectedRowData] = useState<any>(null)

  const navigate = useNavigate()

  function filterFunction(data: { email: any; name: any }) {
    return !data?.email && !data?.name
  }

  useEffect(() => {
    getData({ setData, path: PATH, getMap, isCollection: true, filterFunction } as any)
  }, [])

  useEffect(() => {
    if (data?.length) {
      const resultColumns = getColumns({
        imagePropName: IMAGE_PROP_NAME,
        data,
        setLoading,
        setData,
        path: PATH,
        onEditSuccess: async ({ result, close, id }: any) => {
          await updateData({
            path: `${PATH}/${id}`,
            setLoading,
            data: result.formData,
          } as any)

          getData({
            setData,
            path: PATH,
            getMap,
            isCollection: true,
            setLoading,
            filterFunction,
          })
          close()
        },
      } as any)
      setColumns(resultColumns)
    }
  }, [data])

  if (!data || !Array.isArray(data)) {
    setData([])
  }

  return (
    <div>
      <DataTable
        isCollection
        imagePropName={IMAGE_PROP_NAME}
        path={PATH}
        bucket={BUCKET}
        title={TITLE}
        addTitle={ADD_TITLE}
        setLoading={setLoading}
        setData={setData}
        getMap={getMap}
        loading={loading}
        data={data}
        columns={columns}
        options={options}
        schema={schema}
        onRowClicked={(rowData: any, rowMeta: { dataIndex: string | number }) => {
          const d = data?.[rowMeta.dataIndex]
          navigate(`/users/${d.id}`)
          // console.log("selectedRowData------->", d)
          // setSelectedRowData(d)
          // PopupService.setPopup(
          //   <UserDetail
          //     selectedRowData={d}
          //     setLoading={setLoading}
          //     setSelectedRowData={setSelectedRowData}
          //   />,
          // )
          // setOpenDetails(true);
        }} //rowData: string[], rowMeta: { dataIndex: number; rowIndex: number }
        onSuccess={
          (() => {
            getData({ setData, path: PATH, getMap, isCollection: true, setLoading, filterFunction })
          }) as any
        }
        onEditSuccess={() => {
          getData({ setData, path: PATH, getMap, isCollection: true, setLoading, filterFunction })
        }}
        onAddSuccess={async ({ result, close }: any) => {
          await add({
            path: PATH,
            setLoading,
            data: result.formData,
          } as any)

          getData({ setData, path: PATH, getMap, isCollection: true, setLoading, filterFunction })
          close()
        }}
      />

      {/* <Popup
      title="User Details"
      open={openDetails}
      onClose={setOpenDetails}
    >
      {close => {
        if (!selectedRowData) {
          return null;
        }
        return <UserDetail close={close} selectedRowData={selectedRowData} setLoading={setLoading} setSelectedRowData={setSelectedRowData} />;
      }}
    </Popup> */}
    </div>
  )
}

export function insertSpaces(str: string) {
  return str
    .replace(/(_|-)/g, " ")
    .trim()
    .replace(/\w\S*/g, function (str: string) {
      return str.charAt(0).toUpperCase() + str.substr(1)
    })
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2")
  // string = string.replace(/([a-z])([A-Z])/g, '$1 $2');
  // string = string.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
  // return string;
}
export const registerWithEmailAndPassword = async (name: any, email: string, password: string) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password)
    const user = res.user
    await addDoc(collection(db, "users"), {
      uid: user.uid,
      name,
      authProvider: "local",
      email,
    })
  } catch (err: any) {
    console.error(err)
    alert(err.message)
  }
}

export const sendPasswordReset = async (email: string) => {
  try {
    await sendPasswordResetEmail(auth, email)
    alert("Password reset link sent!")
  } catch (err: any) {
    console.error(err)
    alert(err.message)
  }
}

export const _sendEmailVerification = async (user: any) => {
  try {
    auth.currentUser && (await sendEmailVerification(auth.currentUser))
    alert("Email verification link sent!")
  } catch (err: any) {
    console.error(err)
    alert(err.message)
  }
}

export const logout = () => {
  signOut(auth)
}

export default Main

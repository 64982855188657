import { AnyObj, removeUndefinedFields } from "../../utils"
import {
  DocumentData,
  DocumentReference,
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  limit,
  orderBy,
  query,
  setDoc,
  startAfter,
  updateDoc,
} from "firebase/firestore"
import React, { useEffect, useState } from "react"
import VideoManager, { VideoData } from "react-ui-components/dist/components/Video/VideoManager"

import { Button } from "@mui/material"
import { DisableMessage } from "../../utils/DisableMessage"
import { PopupService } from "react-ui-components/dist/components"
import { data } from "react-router-dom"
import { db } from "../../firebase"

const PAGE_SIZE = 5

const VideoManagerIn: React.FC = () => {
  const [initialVideos, setInitialVideos] = useState<VideoData[]>([])
  const [lastVisible, setLastVisible] = useState<any>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [hasMore, setHasMore] = useState(true)

  const fetchVideos = async (isNextPage = false) => {
    setIsLoading(true)

    try {
      const videosRef = collection(db, "videos")
      let q

      if (isNextPage && lastVisible) {
        q = query(videosRef, orderBy("videoUrl"), startAfter(lastVisible), limit(PAGE_SIZE))
      } else {
        q = query(videosRef, orderBy("videoUrl"), limit(PAGE_SIZE))
      }

      const snapshot = await getDocs(q)

      if (!snapshot.empty) {
        const newVideos = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() } as VideoData))

        setInitialVideos((prev) => (isNextPage ? [...prev, ...newVideos] : newVideos))
        setLastVisible(snapshot.docs[snapshot.docs.length - 1])
        setHasMore(snapshot.docs.length === PAGE_SIZE) // If less than PAGE_SIZE, no more data
      } else {
        setHasMore(false)
      }
    } catch (error) {
      console.error("Error fetching videos:", error)
    }

    setIsLoading(false)
  }

  // Handle Create/Update
  const handleSave = async (video: VideoData, isEdit?: boolean, currentVideo?: VideoData) => {
    try {
      const videoRef =
        isEdit && currentVideo ? doc(db, "videos", currentVideo.id) : doc(collection(db, "videos"))

      await setDoc(videoRef, removeUndefinedFields({ ...video, id: videoRef.id } ) as any, {
        merge: true,
      })
    } catch (error) {
      console.error("Error saving video:", error)
      PopupService.setPopup(error.message)
    }
  }

  // Handle Delete
  const handleDelete = async (video: VideoData) => {
    try {
      await deleteDoc(doc(db, "videos", video.id))
      setInitialVideos(initialVideos?.filter((x) => x.id !== video.id) || [])
    } catch (error) {
      console.error("Error deleting video:", error)
    }
  }

  // Handle Clone
  const handleClone = async (video: VideoData) => {
    try {
      const newDocRef = doc(collection(db, "videos"))
      const formData = {
        ...video,
        id: newDocRef.id,
        title: `${video.title || newDocRef.id} (Copy)`,
      }

      await setDoc(newDocRef, formData)
      setInitialVideos((prev) => [...prev, { ...formData }])
    } catch (error) {
      console.error("Error cloning video:", error)
    }
  }

  useEffect(() => {
    fetchVideos()
  }, [])

  return (
    <div>
      <div className="flex justify-center mt-4">
        {hasMore && (
          <Button variant="contained" onClick={() => fetchVideos(true)} disabled={isLoading}>
            {isLoading ? "Loading..." : "Load More"}
          </Button>
        )}
      </div>
      <VideoManager
        onSave={handleSave}
        // onEdit={handleSave}
        onDelete={handleDelete}
        onDuplicate={handleClone}
        initialVideos={initialVideos}
        DisabledMessage={({ disabled }) => <DisableMessage disabled={disabled} />}
      />
    </div>
  )
}

export default VideoManagerIn
